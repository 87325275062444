.interested-course {
    margin: 0;
    padding: 0;
    padding: 10px;
}

.students-interested-course {
    margin-right: 10px;
    display: flex;
}

.interested-course-form {
    max-width: 100%;
    margin: 0px auto;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    border: 2px solid black;
    color: #0e0e0e;
    font-weight: 400;
    margin-top: 20px;
    font-size: 1.2rem;
}

.interested-course-label {
    margin-bottom: 10px;
    color: #0e0e0e;
    font-weight: 400;
    font-size: 1.4rem;
}

.interested-course-1 {
    width: 100%;
    padding: 8px;
    margin-bottom: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.interested-course-submit {
    width: 100%;
    padding: 4px;
    background: linear-gradient(135deg, #052659, #5483B3);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 20px;
}

.recommendations-container {
    margin-top: 20px;
    background: linear-gradient(45deg, #3868a3, #21629a, #6a91ae);
    border: 1px solid #020202;
    overflow-y: auto;
    max-height: auto;
    width: auto;
    border-radius: 10px;
}

.recommendations-index {
    margin-bottom: 10px;
    margin-left: 20px;
    margin-top: 20px;
    color: white;
    font-weight: 600;
    font-size: 1.8rem;
}

.recommendations-ul {
    list-style-type: none;
    padding: 0;
}

.recommendations-ul a {
    color: white;
    text-decoration: none;
}

.recommendations-ul a:hover {
    color: rgb(0, 0, 0);
}

.recommendations-li {
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 25px;
    text-align: start;
    margin-left: 10px;
    margin-top: 10px;
    padding: 2px;
}

.recommendations-index-flex {
    display: flex;
    align-items: center;
}

.jNeOpF1 {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(32, 226, 215);
    border-radius: 50%;
    display: flex;
    height: 25px;
    font-size: 1.0rem;
    -webkit-box-pack: center;
    justify-content: center;
    width: 25px;
    margin-bottom: 20px;
}

.lfeMSB1 {
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(32, 226, 215);
    border-radius: 50%;
    display: flex;
    height: 25px;
    font-size: 1.4rem;
    -webkit-box-pack: center;
    justify-content: center;
    width: 25px;
}

.lfem1 {
    margin-bottom: 20px;
}

.lfems1 {
    margin-bottom: 20px;
}

.lfems2 {
    margin-bottom: 20px;
    margin-top: 10px;
}

.iZEyIQ1 ul li::after {
    background-color: rgb(204, 204, 204);
    content: " ";
    height: 20px;
    left: calc((45% - 1px));
    position: absolute;
    top: 25px;
    width: 3px;
}

.iZEyIQ1 ul li {
    position: relative;
}

.Circle-sc-fvyqwls-1 {
    font-size: 20px;
    margin-top: 0px;
    list-style: none;
    gap: 40px;
    margin-top: 0px;
}

@media (max-width: 480px) {
    .students-interested-course {
        flex-direction: column;
        margin-right: 10px;
    }
    .interested-course-form {
        max-width: 90%;
        border-radius: 0px;
        margin-top: 20px;
    }
    .interested-course-1 {
        width: 100%;
        border-radius: 0px;
    }
    .recommendations-container {
        width: 90%;
        border-radius: 0px;
        margin-left: 14px;
    }
    .recommendations-index {
        margin-bottom: 10px;
        margin-left: 10px;
        margin-top: 20px;
        font-size: 1.4rem;
    }
    .recommendations-li {
        font-size: 1.2rem;
        margin-left: -20px;
    }
    .recommendations-index-flex {
        display: flex;
        align-items: start;
    }
    .jNeOpF1 {
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border: 0px solid rgb(32, 226, 215);
        border-radius: 0%;
        display: flex;
        height: 0px;
        font-size: 0rem;
        -webkit-box-pack: center;
        justify-content: center;
        width: 0px;
        margin-bottom: 0px;
    }
    .lfeMSB1 {
        -webkit-box-align: center;
        align-items: center;
        background-color: rgb(255, 255, 255);
        border: 0px solid rgb(32, 226, 215);
        border-radius: 0%;
        display: flex;
        height: 0px;
        font-size: 0rem;
        -webkit-box-pack: center;
        justify-content: center;
        width: 0px;
    }
    .lfem1 {
        margin-bottom: 0px;
    }
    .lfems1 {
        margin-bottom: 0px;
    }
    .lfems2 {
        margin-bottom: 0px;
        margin-top: 0px;
    }
    .iZEyIQ1 ul li::after {
        background-color: rgb(204, 204, 204);
        content: " ";
        height: 0px;
        left: calc((0% - 0px));
        position: absolute;
        top: 0px;
        width: 0px;
    }
    .iZEyIQ1 ul li {
        position: relative;
    }
    .Circle-sc-fvyqwls-1 {
        font-size: 0px;
        margin-top: 0px;
        list-style: none;
        gap: 0px;
        margin-top: 0px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .students-interested-course {
        margin-right: 10px;
        flex-direction: column;
    }
}