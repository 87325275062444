.students-courses-achievements {
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%);
    padding: 40px 0px 80px 0px;
}

.students-courses-achievements-h1 {
    text-align: center;
}

.students-courses-achievements-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 140px;
}

.students-courses-achievements-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
}

.students-courses-achievements-students1,
.students-courses-achievements-students2,
.students-courses-achievements-students3,
.students-courses-achievements-students2-1,
.students-courses-achievements-students2-2 {
    width: 180px;
    height: 170px;
    background-color: rgb(2, 47, 96);
    box-shadow: -10px 0px 10px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
}

.students-courses-achievements-students1 {
    background: linear-gradient(to bottom, rgb(1, 39, 79), rgb(82, 136, 194));
}

.students-courses-achievements-students2 {
    background: linear-gradient(to right, #5ec00d, #487b01);
}

.students-courses-achievements-students3 {
    background: linear-gradient(to bottom, #c639e5, #1b0050);
}

.students-courses-achievements-students2-1 {
    background: linear-gradient(to bottom, #34e6f8, #02307a);
}

.students-courses-achievements-students2-2 {
    background: linear-gradient(to bottom, #f3ec06, #958e03);
}

.students-courses-achievements-students1 {
    margin-left: 25px;
}

.students-courses-achievements-students2 {
    margin-right: 6px;
}

.students-courses-achievements-students3 {
    margin-right: 22px;
}

.students-courses-achievements-students2-1 {
    margin-left: 0px;
}

.students-courses-achievements-students2-2 {
    margin-left: 48px;
}

.students-courses-achievements-students1-h1,
.students-courses-achievements-students2-h1,
.students-courses-achievements-students3-h1 {
    background-color: rgb(252, 252, 252);
    margin-top: -22px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 55px;
}

.students-courses-achievements-students-1-h1,
.students-courses-achievements-students-2-h1 {
    background-color: rgb(252, 252, 252);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 55px;
    margin-top: -20px;
}

.students-courses-achievements-students1-h1 h1,
.students-courses-achievements-students2-h1 h1,
.students-courses-achievements-students3-h1 h1,
.students-courses-achievements-students-1-h1 h1,
.students-courses-achievements-students-2-h1 h1 {
    text-align: center;
    color: #000000;
    font-size: 1.4rem;
    font-weight: 700;
    padding-top: 10px;
}

.students-courses-achievements-students1-p,
.students-courses-achievements-students2-p,
.students-courses-achievements-students3-p {
    text-align: center;
    color: white;
    margin-top: 10px;
}

.students-courses-achievements-students-1-p,
.students-courses-achievements-students-2-p {
    text-align: center;
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
}

.students-courses-achievements-students1-p h1,
.students-courses-achievements-students2-p h1,
.students-courses-achievements-students3-p h1,
.students-courses-achievements-students-1-p h1,
.students-courses-achievements-students-2-p h1 {
    text-align: center;
    color: white;
    font-size: 1.8rem;
    font-weight: 700;
    padding-bottom: 10px;
}

.students-courses-achievements-image1 {
    margin-top: -20px;
    width: 90px;
    height: 90px;
}

.students-courses-achievements-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 1020px;
}

@media (max-width: 480px) {
    .students-courses-achievements-1 {
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 0px;
        margin-left: -20px;
    }
    .students-courses-achievements-2 {
        gap: 10px;
        margin-top: 0px;
        margin-left: 0px;
    }
    .students-courses-achievements-students2-2 {
        margin-left: 10px;
    }
    .students-courses-achievements-students1,
    .students-courses-achievements-students2,
    .students-courses-achievements-students3,
    .students-courses-achievements-students2-1,
    .students-courses-achievements-students2-2 {
        width: 135px;
        height: auto;
    }
    .students-courses-achievements-students3 {
        margin-top: 40px;
        margin-bottom: 20px;
    }
    .students-courses-achievements-students1-h1,
    .students-courses-achievements-students2-h1,
    .students-courses-achievements-students3-h1 {
        height: 55px;
    }
    .students-courses-achievements-students-1-h1,
    .students-courses-achievements-students-2-h1 {
        height: 55px;
        margin-top: -20px;
    }
    .students-courses-achievements-students1-h1 h1,
    .students-courses-achievements-students2-h1 h1,
    .students-courses-achievements-students3-h1 h1,
    .students-courses-achievements-students-1-h1 h1,
    .students-courses-achievements-students-2-h1 h1 {
        font-size: 1.4rem;
    }
    .students-courses-achievements-students1-p h1,
    .students-courses-achievements-students2-p h1,
    .students-courses-achievements-students3-p h1,
    .students-courses-achievements-students-1-p h1,
    .students-courses-achievements-students-2-p h1 {
        font-size: 1.6rem;
    }
    .students-courses-achievements-image {
        width: 0%;
        margin: 0px auto;
    }
    .students-courses-achievements-image1 {
        width: 70px;
        height: 70px;
    }
}

@media (min-width: 481px) and (max-width: 1023px) {
    .students-courses-achievements-1,
    .students-courses-achievements-2 {
        flex-direction: row;
        gap: 50px;
    }
    .students-courses-achievements-2 {
        gap: 6px;
    }
    .students-courses-achievements-students1,
    .students-courses-achievements-students2,
    .students-courses-achievements-students3,
    .students-courses-achievements-students2-1,
    .students-courses-achievements-students2-2 {
        width: 180px;
        height: 180px;
        font-size: 1em;
    }
    .students-courses-achievements-students1-h1,
    .students-courses-achievements-students2-h1,
    .students-courses-achievements-students3-h1,
    .students-courses-achievements-students-1-h1,
    .students-courses-achievements-students-2-h1 {
        height: 50px;
    }
    .students-courses-achievements-image {
        width: 100%;
        margin: 0 auto;
    }
    .students-courses-achievements-image1 {
        width: 80px;
        height: 80px;
    }
}