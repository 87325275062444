.homepagecoloresiot1 {
    /* background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%); */
    background-color: rgb(255, 255, 255);
    inline-size: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    block-size: 91%;
}

.courses-iot-empowering {
    display: flex;
    padding: 60px 100px 10px 100px;
    gap: 80px;
    background-color: #ffff;
}

.courses-iot-empowering ul {
    list-style: none;
    padding: 20px 0px 0px 0px;
    width: 800px;
}

.IoTUniverse-h1,
.IoTUniverse-h1-span,
.IoTUniverse-p,
.iotuniversediv-career-path-h1,
.iotuniversediv-career-path-p,
.iotuniversediv-career-path-salary-p,
.iotuniversediv-career-path-salary-p1,
.embeddedsystems-h1,
.IoTUniverse-1h1,
.IoTUniverse1-p,
.IoTUniverse1-h1,
.IoTUniverse-card-title,
.IoTUniverse2-h1-mastery,
.IoTUniverse2-card-title-learn-paths,
.IoTUniverse3-card-title-paths-boards,
.IoTUniverse3-h1,
.IoTUniverse3-h1-1,
.IoTUniverse4-h1,
.IoTUniverse4-h1-1,
.IoTUniverse4-h1-1,
.IoTUniverse4-p,
.IoTUniverses4-h1,
.IoTUniverses4-p,
.IoTUniverses4-li,
.IoTUniverses4-p1,
.IoTUniverse5-h1,
.IoTUniverse5-card-title-paths,
.IoTUniverse5-card-title-paths-p,
.IoTUniverse6-h1,
.futureofiot-icon,
.IoTUniverse4-li-fastar {
    /* color: #0e0e0e; */
    color: rgb(5, 38, 89);
}

.IoTUniverse-h1 {
    font-weight: 700;
    font-size: 46px;
    padding: 20px 60px 0px 0px;
    color: #1c84e4;
}

.IoTUniverse-p {
    margin: 0;
    padding-top: 10px;
    font-weight: 400;
    font-size: 1.4rem;
    color: #000000;
    padding: 0px 40px 0px 4px;
}

.IoTUniverse-btn {
    width: auto;
    margin-bottom: 20px;
    background-color: #026fd6;
    /* background: linear-gradient(135deg, #5483B3, #052659); */
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    border-radius: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
    padding: 10px;
}

.IoTUniverse-btn:hover {
    /* background: linear-gradient(135deg, #052659, #5483B3); */
    background-color: #0357a5;
    color: white;
}

.IoTUniverse-btn-p {
    margin-top: 30px;
}

.thirdman-iot {
    width: 450px;
    height: 350px;
    margin-bottom: 0px;
    border-radius: 20px;
}

.iotuniversediv-career-pathseses {
    padding: 0px 100px 40px 90px;
    background-color: #ffff;
}

.iotuniversediv-career-pathses {
    padding: 25px 0px 25px 0px;
    /* margin: 0px 40px 0px 36px; */
    width: auto;
    /* background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%); */
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
}

.iotuniversediv-career-path {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.iotuniversediv-career-path-h1 {
    font-weight: 700;
    font-size: 26px;
    text-align: start;
    color: #052659;
    margin: 0px 0px 0px 20px;
}

.iotuniversediv-career-path-p {
    text-align: start;
    font-size: 1.2rem;
    font-weight: 400;
    color: #222121;
    margin: 0px 60px 0px 20px;
}

.iotuniversediv-career-path-salarys {
    width: 380px;
    height: 160px;
    margin: 0px 30px 20px 0px;
    background-color: #052659;
}

.iotuniversediv-career-path-salary {
    width: 380px;
    height: 160px;
    background-color: white;
    margin: -10px 0px 10px 10px;
    padding: 20px;
    border: 2px solid rgb(120, 120, 120);
}

.iotuniversediv-career-path-salary-p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 20px 0px 0px 0px;
}

.iotuniversediv-career-path-salary-p1 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 700;
}

.IoTUniversediv {
    background-color: white;
    padding: 20px 100px 20px 100px;
}

.IoTUniverse-1h1 {
    font-size: 26px;
    font-weight: 600;
    text-align: start;
    color: #1c84e4;
}

.futureofiot-icon {
    width: 75px;
    height: 65px;
    margin-right: 6px;
}

.IoTUniverse1-h1 {
    padding-top: 3px;
    padding-bottom: 10px;
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.IoTUniverse1-p {
    margin: 0;
    font-weight: 400;
    font-size: 1.4rem;
    text-align: center;
    color: #222121;
}

.IoTUniverse-internet-cardflexs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    gap: 40px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 40px;
}

.IoTUniverse-card1,
.IoTUniverse-card2,
.IoTUniverse-card3 {
    width: 350px;
    height: 250px;
    background-color: white;
    border-radius: 20px;
    margin: 0px 20px 30px 20px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.5);
}

.IoTUniverse-card1:hover,
.IoTUniverse-card2:hover,
.IoTUniverse-card3:hover {
    transform: scale(1.0);
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
    box-shadow: 0 0 10px rgba(163, 162, 163, 0.8);
}

.IoTUniverse-iote,
.IoTUniverse-iote-projects {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.IoTUniverse-iote {
    width: 140px;
    height: 110px;
    margin-top: 10px;
}

.IoTUniverse-iote-projects {
    width: 180px;
    height: 110px;
    margin-top: 10px;
}

.IoTUniverse-card-body {
    padding: 4px;
}

.IoTUniverse-card-title {
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
    padding: 10px;
    color: #0445a8;
}

.IoTUniverse-card-titles {
    font-weight: 700;
    font-size: 1.2rem;
    text-align: center;
    padding: 10px 60px 10px 60px;
    color: #0445a8;
}

.IoTUniverse2 {
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
    padding: 0px 100px 80px 100px;
}

.IoTUniverse2-h1-mastery {
    color: #1c84e4;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    padding: 20px 0px 20px 0px;
}

.IoTUniverse3 {
    background-color: #ffff;
    padding: 20px 100px 10px 100px;
}

.IoTUniverse3-h1 {
    margin: 0;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    color: #1c84e4;
    padding: 20px 100px 10px 100px;
}

.IoTUniverse3-h1-1 {
    margin: 0;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    color: #222121;
    padding-top: 0px;
}

.IoTUniverse4 {
    padding: 20px 100px 20px 100px;
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);

}

.IoTUniverse4-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
}

.IoTUniverse4-h1 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    padding: 0px 0px 30px 0px;
    color: #1c84e4;
}
.iotuniverses4-pdf{
    padding: 40px 10px 20px 10px;
}
.IoTUniverse-pdf-h1 {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    padding: 4px 0px 0px 0px;
}

.IoTUniverses4-pdf {
    display: flex;
    justify-content: center;
    font-size: 18px;
    align-items: center;
    color: #0445a8;
    font-weight: 700;
}

.IoTUniverse4-flex ul {
    list-style: none;
}

.IoTUniverse4-li {
    display: flex;
}

.IoTUniverse4-li-fastar {
    padding: 4px 10px;
}

.IoTUniverse4-p {
    font-weight: 400;
    font-size: 1.4rem;
    text-align: start;
    color: rgb(2, 52, 128);
}

.IoTUniverse4-imgs-iot {
    width: 400px;
    height: 300px;
    margin: 20px;
    border-radius: 20px;
}
.IoTUniverses4-h1 {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #1c84e4;
}

.IoTUniverses4-p {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
}

.ioiuniverses4 ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style: none;
}

.IoTUniverses4-li {
    font-size: 1.1rem;
    font-weight: 700;
    color: white;
    padding: 10px;
}

.IoTUniverses4-iot-img {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0px 40px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.5);
}

.IoTUniverses4-li-p {
    background-color: #052659;
    width: 240px;
    height: 180px;
    padding: 20px;
    margin: 0px 20px 30px 0px;
    text-align: center;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.IoTUniverses4-li-p:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.IoTUniverses4-p1 {
    text-align: center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.8rem;
    padding: 20px;
}

.IoTUniverse5 {
    background-color: rgb(255, 255, 255);
    padding: 20px 0px 20px 0px;
}

.IoTUniverse5-h1 {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    color: #1c84e4;
}

.IoTUniverse6 {
    background-color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.IoTUniverse6-h1 {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    color: #1c84e4;
    padding-top: 20px;
}

.iot-constellation-interactive {
    padding: 10px;
}

.iot-constellation-interactive-p {
    color: #0e0e0e;
    margin-left: 40px;
}

@media (max-width: 480px) {
    .courses-iot-empowering {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        padding: 20px;
    }
    .IoTUniverse-h1 {
        font-size: 30px;
    }
    .IoTUniverse-p {
        font-size: 1.2rem;
    }
    .IoTUniverse-btn {
        width: auto;
        margin-bottom: 0px;
        border-radius: 0px;
    }
    .IoTUniverse-btn-p {
        margin-top: 20px;
    }
    .thirdman-iot {
        width: auto;
        height: auto;
        border-radius: 0px;
        margin-bottom: 20px;
    }
    .iotuniversediv-career-pathseses {
        padding: 0px;
    }
    .iotuniversediv-career-pathses {
        padding: 10px;
        margin: 10px 10px 40px 10px;
        width: auto;
        height: auto;
        border-radius: 0px;
    }
    .iotuniversediv-career-path {
        flex-wrap: wrap;
    }
    .iotuniversediv-career-path-h1 {
        font-size: 24px;
        margin: 0px;
    }
    .iotuniversediv-career-path-p {
        margin: 0px;
    }
    .iotuniversediv-career-path-salarys {
        width: 260px;
        height: auto;
        margin: 40px 10px 20px 0px;
    }
    .iotuniversediv-career-path-salary {
        width: 260px;
        height: auto;
    }
    .iotuniversediv-career-path-salary-p {
        text-align: center;
        font-size: 1.2rem;
        font-weight: 700;
        padding: 20px 0px 0px 0px;
    }
    .iotuniversediv-career-path-salary-p1 {
        font-size: 1.6rem;
    }
    .IoTUniversediv {
        padding: 20px 0px 20px 0px;
    }
    .IoTUniverse-1h1 {
        font-size: 24px;
        text-align: center;
    }
    .futureofiot-icon {
        width: 60px;
        height: auto;
    }
    .IoTUniverse1-h1 {
        font-size: 20px;
    }
    .IoTUniverse1-p {
        font-size: 1.2rem;
        text-align: center;
    }
    .IoTUniverse-internet-cardflexs {
        flex-direction: column;
        margin: 40px 20px 20px 20px;
    }
    .IoTUniverse-card1,
    .IoTUniverse-card2,
    .IoTUniverse-card3 {
        width: auto;
        height: auto;
        border-radius: 20px;
        margin: 0px 0px 20px 0px;
    }
    .IoTUniverse-card-body {
        padding: 4px;
    }
    .IoTUniverse-iote,
    .IoTUniverse-iote-projects {
        margin-left: auto;
        margin-right: auto;
    }
    .IoTUniverse-card-titles {
        font-size: 1.2rem;
        text-align: center;
        padding: 0px 10px 10px 10px;
    }
    .IoTUniverse2-h1-mastery {
        font-size: 26px;
        padding: 10px 10px 0px 10px;
    }
    .IoTUniverse3-h1 {
        font-size: 24px;
        padding: 10px 10px 0px 10px;
    }
    .IoTUniverse3-h1-1 {
        font-size: 1.2rem;
        padding: 4px 10px 0px 10px;
    }
    .IoTUniverse4 {
        margin: 0px 0px 10px 0px;
        padding: 0px 0px 10px 0px;
    }
    .IoTUniverse4-flex {
        flex-wrap: wrap;
        width: auto;
        margin: 10px 10px 40px 10px;
        padding: 0px 0px 10px 0px;
        border-radius: 0px;
    }
    .IoTUniverse4-h1 {
        font-size: 24px;
    }
    .IoTUniverse4-flex ul {
        margin: 20px 0px 0px 0px;
        padding: 0px;
        height: auto;
        border-radius: 0px;
    }
    .IoTUniverse4-li {
        font-size: 1rem;
    }
    .IoTUniverses4-li-p {
        margin-top: 20px;
    }
    .IoTUniverse4-imgs-iot {
        width: 280px;
        height: auto;
        margin-left: 0px;
        margin-right: 0px;
        border-radius: 0px;
    }
    .IoTUniverses4-h1 {
        font-size: 24px;
    }
    .IoTUniverses4 ul {
        margin: 20px;
    }
    .IoTUniverses4-p {
        padding: 0px 10px 0px 10px;
    }
    .IoTUniverse5-h1 {
        font-size: 24px;
        padding-top: 10px;
    }
    .IoTUniverse6 {
        padding-bottom: 0px;
        margin: 60px 0px 0px 0px;
    }
    .IoTUniverse6-h1 {
        font-size: 24px;
        padding-top: 20px;
    }
}


/* Tablet styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .courses-iot-empowering {
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        padding: 40px 0px 40px 40px;
        padding-top: 40px;
    }
    .IoTUniverse-h1 {
        font-size: 50px;
    }
    .IoTUniverse-btn-p {
        margin-top: 30px;
    }
    .thirdman-iot {
        width: 400px;
        height: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .iotuniversediv-career-path {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .iotuniversediv-career-path-h1 {
        text-align: center;
    }
    .iotuniversediv-career-path-p {
        padding: 0px 20px 0px 20px;
    }
    .iotuniversediv-career-path-salarys {
        margin-top: 40px;
    }
    .IoTUniversediv {
        padding: 40px;
    }
    .IoTUniverse-1h1 {
        padding: 20px 0px 20px 0px;
        font-size: 30px;
    }
    .IoTUniverse1-h1 {
        font-size: 30px;
    }
    .IoTUniverse-card1,
    .IoTUniverse-card2,
    .IoTUniverse-card3 {
        width: 300px;
        height: auto;
        margin-top: 20px;
    }
    .IoTUniverse-iote {
        width: 100px;
        height: 80px;
        margin-top: 10px;
    }
    .IoTUniverse-iote-projects {
        width: 100px;
        height: 80px;
        margin-top: 10px;
    }
    .IoTUniverse-card-body {
        padding: 4px;
    }
    .IoTUniverse-card-title,
    .IoTUniverse-card-titles {
        font-weight: 600;
        font-size: 1.2rem;
        text-align: center;
    }
    .IoTUniverse4-flex {
        margin-bottom: 20px;
        flex-wrap: wrap;
        padding: 40px 0px 0px 10px;
    }
    .IoTUniverse4-h1 {
        font-size: 30px;
        text-align: center;
        padding-top: 60px;
    }
    .IoTUniverse4-h1-1 {
        font-size: 1.6rem;
        text-align: start;
        margin: 20px 0px 10px 20px;
    }
    .IoTUniverse4-p {
        font-size: 1.2rem;
        margin: 0px 20px 0px 20px;
    }
    .IoTUniverses4-li-p {
        margin-top: 20px;
    }
    .IoTUniverse4-imgs-iot {
        width: 400px;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        margin-bottom: 40px;
        border-radius: 10px;
    }
    .IoTUniverse5-h1 {
        font-size: 30px;
        text-align: center;
        padding-top: 20px;
    }
    .IoTUniverse6-h1 {
        font-size: 30px;
        text-align: center;
        padding-top: 20px;
    }
    .iot-constellation-interactive {
        padding: 10px;
    }
    .iot-constellation-interactive-p {
        color: #0e0e0e;
        margin-left: 40px;
    }
}