.homepage-experience-learners {
    text-align: center;
    padding: 2rem;
    background-color: rgb(5, 38, 89);
}

.huebits-experience-homepages1-students-h1 {
    font-size: 40px;
    color: rgb(5, 38, 89);
    margin-bottom: 1rem;
    font-weight: 700;
}

.huebits-homepages1-review-p {
    font-size: 1.2rem;
    color: rgb(102, 102, 102);
    margin-bottom: 2rem;
}

.homepage-googlereview {
    width: 300px;
    margin-bottom: 2rem;
}

.homepage-reviewsflexs {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    padding: 40px;
}

.homepage-slide-items-color {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.homepage-slide-item {
    background-color: rgb(5, 38, 89);
    border-radius: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 2rem;
    text-align: center;
    width: 320px;
    height: 380px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.homepage-slide-item-p {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px auto auto auto;
    background-color: rgb(255, 255, 255);
    font-size: 1.6rem;
    font-weight: 700;
    color: rgb(5, 38, 89);
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.homepage-card-body {
    padding-top: 1rem;
}

.homepage-card-title-paths {
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    margin-bottom: 0.5rem;
}

.homepage-card-title-paths-p {
    font-size: 13px;
    color: white;
    line-height: 1.5;
}

.slick-prev,
.slick-next {
    background-color: rgb(5, 38, 89);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    height: 40px;
    width: 40px;
    z-index: 1;
}

.slick-prev:hover,
.slick-next:hover {
    background-color: rgb(4, 56, 133);
}

@media (max-width: 480px) {
    .huebits-experience-homepages1-students-h1 {
        font-size: 30px;
        padding: 10px;
    }
    .homepage-slide-item {
        border-radius: 0px;
        width: 260px;
        height: 420px;
    }
    .homepage-slide-items-color {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .homepage-reviewsflexs {
        padding: 20px;
    }
}

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .homepage-slide-item {
        width: 300px;
        height: 400px;
    }
}