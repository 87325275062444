/* Main container */

.learning-path-container {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.learning-path-cards {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 40px;
    /* margin-top: 10px; */
    width: 90%;
}


/* Card styling */

.learning-path-card {
    background-color: #86c5fb;
    padding: 20px;
    text-align: center;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.6);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.learning-path-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.learning-path-image {
    width: 100px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 15px;
    /* filter: brightness(0) invert(1); */
}

.learning-path-title {
    font-size: 1.2rem;
    font-weight: 700;
    color: #052659;
    margin-bottom: 20px;
}

.learning-path-button {
    background: linear-gradient(135deg, #052659, #5483B3);
    color: white;
    font-weight: 700;
    font-size: 20px;
    padding: 6px;
    width: 200px;
    border: none;
    transition: background 0.3s ease, transform 0.3s ease;
}

.learning-path-button:hover {
    background: linear-gradient(135deg, #5483B3, #052659);
    transform: scale(1.05);
}

.coming-soon {
    background: #888;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .learning-path-title {
        font-size: 1.1rem;
    }
    .learning-path-image {
        width: 80px;
        height: 60px;
    }
    .learning-path-button {
        font-size: 14px;
        padding: 10px 18px;
    }
}

@media (max-width: 480px) {
    .learning-path-container {
        padding: 10px;
    }
    .learning-path-card {
        padding: 10px;
    }
}