.students-comingsoon {
    font-weight: 700;
    font-size: 46px;
    text-align: center;
    padding: 40px;
}

.students-comingsoon-capstonestart {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    border-radius: 20px;
    font-weight: 700;
    font-size: 25px;
    line-height: 1.25;
    padding: 4px 10px 8px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.students-comingsoon-capstonestart:hover {
    background: linear-gradient(135deg, #052659, #5483B3);
    transform: scale(1.1) translateY(-5px);
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .capstone1 {
        text-align: center;
    }
    .capstone {
        margin-left: 40px;
        margin-right: 40px;
    }
}