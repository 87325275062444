.smartcity-slider-container {
    padding: 20px;
    text-align: center;
    /* border-radius: 10px; */
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.iot-smartcity-slides {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-bottom: 20px;
}

.iot-smartcity-slide-item {
    flex: 1 1 calc(25% - 20px);
    max-width: calc(25% - 20px);
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px 0px;
    padding: 10px;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.iot-smartcity-slide-item:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

.iot-courses-list-images {
    width: 282px;
    height: 170px;
    border-radius: 10px;
    display: block;
    border-bottom: 1px solid #ddd;
}

.IoTUniverse3-card-courses-iot {
    padding: 15px;
    text-align: center;
}

.IoTUniverse3-card-title-paths-courses {
    font-size: 14px;
    font-weight: 600;
    color: #636363;
    text-align: start;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}
.pagination-controls button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    width: 40px;
    height: 40px;
    padding: 10px;
    border: none;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
/* 
.pagination-controls button.active-page {
    background-color: #0056b3;
} */
/* 
.pagination-controls button:hover {
    background-color: #0056b3;
} */

.pagination-controls button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination-numbers{
    display: flex;
}
.pagination-numbers button {
    display: flex;
    margin: 0 5px;
    color: #000000;
    background-color: #ffff;
    cursor: pointer;
    font-size: 16px;
    transition: all 0.3s ease;
}

.pagination-numbers button.active-page {
    background-color: #ffff;
    color: #0445a8;
}
.IoTUniverse3-card-title-paths-p {
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
    text-align: center;
}

.iot-smartcity-slide-item:hover .IoTUniverse3-card-title-paths-p {
    opacity: 1;
}

.IoTUniverse3-card-title-paths-p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.4;
}


@media (max-width: 768px) {
    .iot-smartcity-slide-item {
        flex: 1 1 calc(40% - 20px); /* Two items per row on tablets */
        max-width: calc(40% - 20px);
    }
}

@media (max-width: 480px) {
    .iot-smartcity-slide-item {
        flex: 1 1 100%; /* One item per row on mobile */
        max-width: calc(80%);
    }
}
