html,
body,
#root,
.app,
.content {
    height: 100%;
    width: 100%;
    font-style: normal;
    font-stretch: normal;
    line-height: initial;
    font-family: 'Poppins', sans-serif;
}

.app {
    display: flex;
    position: relative;
}

::-webkit-scrollbar {
    width: 6px;
    border-radius: 40px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #e0e0e0;
    border-radius: 40px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 40px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #555;
}