.learningpathprogressbar-backend {
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #97bbdf;
    height: 220px;
    border-radius: 20px;
    width: 280px;
}

.learningpath-progress-bar {
    width: 140px;
    height: 50px;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
}

.learningpathprogressbar {
    margin-top: 14px;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 4px;
    text-align: center;
    color: #052659;
}

.progressbar-text {
    font-weight: 700;
}

.custom-progressbar {
    color: black;
}

@media (max-width: 480px) {
    .learningpathprogressbar-backend {
        height: 200px;
        width: 270px;
        margin: 20px;
    }
    .learningpath-progress-bar {
        width: 120px;
        height: 50px;
        margin-top: 0px;
    }
    .learningpathprogressbar {
        margin-top: 10px;
        padding-bottom: 10px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .learningpathprogressbar-backend {
        width: 220px;
        height: 200px;
    }
    .learningpath-progress-bar {
        width: 120px;
        height: 45px;
        margin-top: 0px;
    }
    .learningpathprogressbar {
        font-size: 16px;
        margin-bottom: 0px;
    }
}