.students-works-dashboard-projects {
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
}

.students-form-resume-profile {
    margin: 20px;
}

.students-dashboard-profile-skills input {
    color: rgb(52, 52, 186);
    font-weight: 700;
}

.students-dashboard-works-apply {
    display: flex;
}

.students-dashboard-resume {
    margin: 20px;
}

@media (max-width: 480px) {
    .students-dashboard-works-apply {
        flex-direction: column;
    }
}

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .students-dashboard-profile-skills input {
        font-size: 14px;
    }
    .students-works-dashboard-projects {
        height: 550px;
    }
    .students-dashboard-works-apply {
        flex-direction: column;
    }
}