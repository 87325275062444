.companies-flex {
    width: 100%;
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(5, 38, 89) 100%);
}

.getstarted-flex {
    display: flex;
    align-items: center;
}

.companies-getstarted-flex-1 {
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(5, 38, 89) 100%);
}

.getstarted-flex ul {
    list-style: none;
    margin: 0;
}

.brilliant,
.build,
.spansexports,
.customersbuild-h1,
.solutions-for-Companies-companiess,
.solutions-for-Companies-h1,
.solutions-for-Companies-1h1,
.solutions-for-Companies-p,
.solutions-for-Companies2-p,
.solutions-for-Companies-p1,
.solutions-for-Companies-p2,
.companies-center,
.companiess,
.card-title,
.card-text,
.customers-reviews-h1,
.creativeproductsh1,
.spans-2,
.Choose,
.inr2-3 p,
.inr2-4 p,
.compaines-hiretechtalent1-h1,
.compaines-hiretechtalent1-container1-h1,
.companies-products-1,
.howitworks-1,
.HowitWorks .spans-1 {
    color: rgb(5, 38, 89);
}

.compaines-buttonget-1,
.btn-Learn-More,
.btn-post-projects-link,
.btn-post-projects-link-manth {
    background: linear-gradient(135deg, rgb(84, 131, 179), rgb(5, 38, 89));
}

.compaines-buttonget-1:hover,
.btn-Learn-More:hover,
.btn-post-projects-link:hover,
.btn-post-projects-link-manth:hover {
    background: linear-gradient(135deg, rgb(5, 38, 89), rgb(84, 131, 179));
    color: rgb(255, 255, 255);
}

@keyframes slideFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.brilliant {
    font-weight: 700;
    color: rgb(2, 41, 100);
    font-size: 46px;
    margin: 20px 0px 20px 80px;
}

.brilliant .span1 {
    color: #1c84e4;
    font-size: 44px;
    padding: 20px 0px 20px 0px;
}

.brilliant .span2 {
    color: rgb(2, 41, 100);
}

.spansexports {
    margin: 0px 80px 0px 85px;
    font-weight: 100;
    font-size: 1.4rem;
    line-height: 2rem;
    color: rgb(0, 0, 0);
}

.buttonget-companies {
    margin-left: 80px;
    display: flex;
    margin-top: 20px;
}

.compaines-buttonget-1 {
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: auto;
    height: auto;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
    margin: 10px 20px 20px 0px;
    padding: 10px 20px;
}

.faArrowRight {
    margin-left: 10px;
    font-size: 16px;
}

.huebitsimgdiv {
    margin-top: 0px;
}

.Thirdman {
    width: 550px;
    height: 550px;
    margin: 20px 90px 20px 0px;
}

.customersbuild {
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(5, 38, 89) 100%);
    padding: 40px 0px 40px 0px;
}

.customersbuild-h1 {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
}

.customersbuild-imgs {
    display: flex;
    overflow: hidden;
    margin-right: auto;
    margin-left: auto;
    width: max-content;
    animation: scrollCenter 10s linear infinite;
    margin-top: 40px;
}

@keyframes scrollCenter {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-50% + 75px));
    }
}

.customers-imges {
    width: 200px;
}

.customers-imges,
.customers-imgs {
    height: 100px;
    margin-left: 40px;
}

.customers-imgs {
    width: 100px;
}

.solutions-for-Companies {
    padding: 40px 0px;
}

.solutions-for-Companies-companiess {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    padding: 0px 0px 40px 0px;
}

.companies-page-center {
    /* background-color: #ffffff; */
    padding: 20px 0px;
}

.companiess {
    padding-top: 40px;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
}

.companiesspan {
    font-weight: 400;
    font-size: 1.4rem;
    padding-bottom: 10px;
    text-align: center;
    color: rgb(0, 0, 0);
}

.btn-Learn-More {
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-left: 6px;
    width: 180px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
}

.btn-Learn-Morep1 {
    padding-top: 20px;
    margin-left: 20px;
}

.btn-Learn-Morep2 {
    padding-top: 40px;
    margin-left: 20px;
}

.coming-soon1{
    margin-top: 24px;
}
.Choose-Services {
    padding: 40px 0px 40px 0px;
}

.Choose {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
}

.inr2-3-lakh-flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 20px 0px 40px 0px;
}

.spanbusiness {
    margin-top: 50px;
}

.inr2-3-box,
.inr2-4-box {
    border-radius: 20px;
    align-items: center;
    width: 480px;
    height: 450px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-width: 6px;
    margin-top: 20px;
}

.inr2-4-box {
    margin-left: 40px;
}

.companies-inr2-3,
.companies-inr2-4 {
    border-radius: 20px;
    box-shadow: 0 20px 20px rgba(235, 234, 234, 0.021), 0 0 20px rgba(232, 231, 231, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
    align-items: center;
    width: 440px;
    height: 410px;
    border-bottom: 10px solid rgb(84, 131, 179);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-width: 10px;
    margin: 40px 0px 0px 10px;
    background-color: rgb(255, 255, 255);
}

.companies-inr2-3:hover,
.companies-inr2-4:hover {
    transform: scale(1.04);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
}

.companies-inr2-3::before,
.companies-inr2-4::before {
    transition: transform 0.3s ease-in-out;
}

.companies-inr2-3 p,
.companies-inr2-4 p {
    font-weight: 400;
    font-size: 1.2rem;
    text-align: start;
}

.companies-products-1 {
    font-weight: 600;
    font-size: 1.4rem;
    background-color: rgb(5, 38, 89);
    color: rgb(255, 255, 255);
    padding: 10px 0px 10px 0px;
    text-align: center;
}

.flex-Internet-of-Things .Internet-of-Things-1 {
    text-align: left;
    font-size: 1.4rem;
    margin: 40px 50px 0px 30px;
}

.iotrevolution-month {
    margin-top: 40px;
}

.iotrevolution-annual {
    margin-top: 70px;
}

.btn-post-projects-link,
.btn-post-projects-link-manth {
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 180px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
}

.btn-post-projects-link {
    margin-left: 20px;
}

.fontawsomeicon-companies {
    margin-right: 10px;
}

.btn-post-a-projects-links-div,
.btn-post-a-projects-links-div1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.btn-post-a-projects-links-div {
    padding-top: 25px;
}

.btn-post-a-projects-links-div1 {
    padding-top: 70px;
}

.compaines-hiretechtalent1 {
    padding: 30px;
}

.compaines-hiretechtalent1-container {
    margin: 10px auto;
    max-width: 90%;
}

.compaines-hiretechtalent1-h1 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}

.compaines-hiretechtalent1-p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.5;
    color: #030303;
    text-align: center;
}

.compaines-hiretechtalent1-container2 {
    display: flex;
    flex-wrap: wrap;
    margin: 40px auto;
    justify-content: center;
    align-items: center;
    gap: 100px;
}

.compaines-hiretechtalent1-containers1 {
    margin-top: 140px;
    padding: 0px 0px 20px 0px;
}

.compaines-hiretechtalent1-container1-cord {
    max-inline-size: 600px;
    background-color: rgb(5, 38, 89);
    border-radius: 10px;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.3), -10px -10px 15px rgba(255, 255, 255, 0.9), 5px 5px 10px rgba(0, 0, 0, 0.2), -5px -5px 10px rgba(255, 255, 255, 0.6);
    block-size: auto;
    padding: 20px;
    margin-block-start: 34px;
    color: #ffffff;
    /* transform: rotate(-6deg); */
    /* Rotate to the left */
}

.compaines-hiretechtalent1-container1-h1 {
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
}

.compaines-hiretechtalent1-container1-p {
    font-size: 1.2rem;
}

.compaines-hiretechtalent1-container1-image,
.compaines-hiretechtalent1-container1-image1 {
    width: 500px;
    height: auto;
    border-radius: 10px;
    margin: 10px;
}

.compaines-hiretechtalent1-container1-image-phone{
    width: 0px;
    height: 0px;
    margin: 0px;
}

.compaines-hiretechtalent1-containers3 {
    display: flex;
    justify-content: center;
    margin: 20px auto;
}

.contactsales {
    width: 400px;
    height: auto;
}

.howitworks-1 {
    margin-top: 20px;
    font-weight: 700;
    font-size: 40px;
    text-align: center;
}

.HowitWorks .spans-1 {
    display: block;
    margin-top: 20px;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
}

.HowitWorks {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
}

.meetingiconsexperts {
    padding-left: 80px;
}

.webdesignicons {
    padding-left: 90px;
}

.basketsimapleicons {
    margin-left: 100px;
}

.ShareIcon,
.MeetingIcon {
    margin-left: 20px;
}

.BasketSimpleIcon {
    margin-right: 60px;
}

.card-img-top-1 {
    width: 40px;
    height: 40px;
    margin-left: 60px;
    color: rgb(5, 38, 89);
}

.brz-img-1-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.brz-img-1 {
    width: 1080px !important;
    margin-left: auto;
    margin-right: auto;
}

.filtered-image {
    filter: brightness(0) saturate(100%) invert(9%) sepia(86%) saturate(2406%) hue-rotate(201deg) brightness(95%) contrast(104%);
    display: block;
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateX(-100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


/* Phone styles */

@media (max-width: 480px) {
    .companies-getstarted-flex-1,
    .customersbuild {
        background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
    }
    .getstarted-flex {
        flex-direction: column;
        padding-right: 20px;
    }
    .brilliant {
        font-size: 30px;
    }
    .brilliant .span1 {
        font-size: 28px;
    }
    .brilliant,
    .build,
    .spansexports,
    .buttonget-companies,
    .inr2-3-box,
    .inr2-4-box,
    .HowitWorks,
    .ShareIcon,
    .MeetingIcon,
    .WebDesignIcon,
    .BasketSimpleIcon,
    .meetingiconsexperts,
    .webdesignicons,
    .basketsimapleicons {
        margin-left: auto;
        margin-right: auto;
    }
    .brilliant {
        font-size: 30px;
        margin-top: 40px;
    }
    .build {
        font-size: 28px;
        padding-bottom: 10px;
    }
    .spansexports {
        padding: 0px 10px 0px 0px;
        font-size: 1.1rem;
    }
    .buttonget-companies {
        border-radius: 0;
        margin: 20px 0px;
        flex-direction: column;
    }
    .compaines-buttonget-1 {
        width: auto;
        height: auto;
        font-size: 16px;
        border-radius: 0;
        margin-left: 0px;
    }
    .Thirdman {
        width: 90%;
        height: auto;
        margin-left: 10px;
        margin-right: auto;
        padding-top: 30px;
    }
    .customersbuild-h1 {
        font-size: 30px;
        text-align: center;
        margin: 0px 20px 0px 20px;
    }
    .solutions-for-Companies {
        padding: 0px;
    }
    .solutions-for-Companies-companiess {
        padding: 20px 0px 0px 0px;
    }
    .companiess {
        padding-top: 20px;
        font-size: 30px;
    }
    .Choose {
        font-size: 24px;
        text-align: center;
    }
    .inr2-3-lakh-flex {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0px 20px 0px 20px;
    }
    .spanbusiness {
        margin-top: 50px;
    }
    .inr2-3-box,
    .inr2-4-box {
        width: 300px;
        height: auto;
        margin-top: 20px;
        margin-bottom: 40px;
    }
    .companies-inr2-3,
    .companies-inr2-4 {
        width: auto;
        height: auto;
        margin: 0px 10px 0px 10px;
    }
    .companies-products-1,
    .companies-products-Project {
        font-size: 20px;
    }
    .companies-inr2-3 p {
        font-size: 1.2rem;
    }
    .companies-inr2-4 p {
        font-size: 1.2rem;
        text-align: left;
        margin-left: 0px;
        padding-left: 0px;
    }
    .fontawsomeicon-companies {
        margin-left: 0px;
        padding-left: 0px;
    }
    .btn-post-projects-link,
    .btn-post-projects-link-manth {
        width: 150px;
        font-size: 16px;
        border-radius: 0px;
        padding-bottom: 4px;
    }
    .compaines-hiretechtalent1 {
        padding: 10px;
    }
    
    .compaines-hiretechtalent1-container {
        max-width: 100%;
    }
    
    .compaines-hiretechtalent1-h1 {
        font-size: 30px;
    }
    
    .compaines-hiretechtalent1-container2 {
        margin: 0px;
        padding:20px;
    }
    
    .compaines-hiretechtalent1-containers1 {
        margin-top: 0px;
        padding: 10px;
    }
    .compaines-hiretechtalent1-container1-image{
        width: 350px;
        height: auto;
        border-radius: 10px;
        margin: -20px 10px -100px 10px;
    }
    .compaines-hiretechtalent1-container1-image1 {
        width: 0;
        height: 0;
        margin: 0;
    }
    .compaines-hiretechtalent1-container1-image-phone {
        width: 350px;
        height: auto;
        border-radius: 10px;
        margin: 60px 10px 40px 10px;
    }
    
    .compaines-hiretechtalent1-containers3 {
        display: flex;
        justify-content: center;
        margin: 20px auto;
    }
    .howitworks-1 {
        margin-top: 0px;
        font-size: 24px;
    }
    .HowitWorks .spans-1 {
        display: block;
        margin-top: 20px;
        font-weight: 400;
        font-size: 1.2rem;
        text-align: center;
    }
    .HowitWorks {
        flex-direction: column;
        padding-top: 40px;
    }
    .spans-1 {
        text-align: center;
    }
    .basketsimapleicons {
        align-items: center;
        margin-left: 10px;
        margin-right: 10px;
    }
    .meetingiconsexperts,
    .webdesignicons,
    .basketsimapleicons,
    .card-img-top-1 {
        padding-left: 0px;
        align-items: center;
        justify-content: center;
    }
    .brz-img-1 {
        width: 0px !important;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .companies-getstarted-flex-1,
    .customersbuild {
        background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
    }
    .getstarted-flex {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
    .brilliant {
        margin: 40px 0 0 20px;
        font-size: 46px;
    }
    .build {
        margin-left: 30px;
        font-size: 40px;
    }
    .spansexports {
        margin: 0 40px 0 30px;
        font-size: 1.4rem;
    }
    .buttonget-companies {
        margin-left: 20px;
    }
    .compaines-buttonget-1 {
        margin-left: 10px;
        width: auto;
        height: auto;
        font-size: 18px;
    }
    .Thirdman {
        width: 80%;
        margin: 50px 20px 0 80px;
        height: auto;
    }
    .customersbuild-h1 {
        margin: 20px 0 40px;
        font-size: 40px;
    }
    .companiesspan {
        font-size: 1.6rem;
        padding-top: 10px;
    }
    .card {
        width: calc(50% - 40px);
    }
    .cardflexs {
        margin: 0 20px;
    }
    .btn-Learn-Morep1,
    .btn-Learn-Morep2 {
        margin: 40px 0 0 70px;
    }
    .customers-reviews-h1 {
        font-size: 38px;
        padding-bottom: 30px;
    }
    .carouselsimages {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .Review1 {
        width: 80%;
        max-width: 100%;
        height: auto;
        border-radius: 15px;
        margin: 0 auto;
        padding: 10px;
    }
    .spans-2 {
        font-size: 1.6rem;
        margin-left: 40px;
        padding-top: 6px;
    }
    .creativeproductsh1 {
        margin-top: 0px;
        text-align: center;
    }
    .creativeproductsh1-spans-2 {
        text-align: center;
        line-height: 2rem;
    }
    .Choose-Services {
        padding: 20px 0;
    }
    .inr2-3-box,
    .inr2-4-box {
        width: auto;
    }
    .inr2-4-box {
        margin-left: 0px;
        height: 450px;
    }
    .btn-post-a-projects-links-div,
    .btn-post-a-projects-links-div1 {
        padding-top: 20;
    }
    .HowitWorks {
        margin: 0px;
    }
    .meetingiconsexperts,
    .webdesignicons,
    .basketsimapleicons {
        padding: 20px;
    }
    .brz-img-1 {
        width: 0px !important;
        margin: 0 auto;
    }
}