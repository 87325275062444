.homepagecolores {
    background-color: rgb(255, 255, 255);
    inline-size: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    block-size: 91%;
}

.homepagecolores {
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%);
}

.homepagecolores-background-flex {
    background-image: url("../../assets/Hueweb.png");
    background-size: cover;
    background-position: center;
    block-size: 98%;
    padding-left: 100px;
}

.homepagecolores-background-flex ul {
    list-style: none;
    margin: 0 auto;
}

.huebits-homepages-spark-spark {
    font-weight: 700;
    font-size: 54px;
    padding-top: 200px;
    width: 700px;
    max-width: 800px;
    color: rgb(255, 255, 255);
    text-align: start;
}
.huebits-homepages-spark-spark span{
    color: rgb(255, 255, 255);
}
.huebits-homepages-spark-spark-p{
    text-align: start;
    font-size: 1.4rem;
    color: rgb(234, 234, 234);
    width: 550px;
    max-width: 800px;
    padding: 10px 0px;
}

/* .homepagecolores-background-flex {
    background-image: url("../../assets/Hueweb1.png");
    background-size: cover;
    background-position: center;
    justify-content: center;
    align-items: center;
    block-size: 100%;
}

.homepagecolores-background-flex ul {
    list-style: none;
    margin: 0 auto;
}

.huebits-homepages-spark-spark {
    font-weight: 700;
    font-size: 50px;
    padding-top: 240px;
    color: rgb(255, 255, 255);
    text-align: center;
}
.huebits-homepages-spark-spark-p{
    text-align: start;
    font-size: 1.4rem;
    color: rgb(255, 255, 255);
    padding: 10px 0px;
}
.huebits-homepages-spark-spark .blue-text {
    color: rgb(242, 239, 239);
    font-weight: 200;
}

.homepage-students-buttonget-1:hover {
    transform: scale(1.1);
}

.homepage-students-companies-buttonget {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    align-items: center;
}

.homepage-students-buttonget-1 {
    background-color: rgb(5, 38, 89);
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    width: 300px;
    height: 60px;
    font-weight: 400;
    font-size: 24px;
    line-height: 1.25;
    margin-top: 20px;
    margin: 0 auto;
    border-radius: 20px;
}

#homepage-students-buttonget-1 {
    background-color: rgba(39, 35, 34);
    margin-right: 20px;
} */

.learners-huebits-homepages-students-color{
    justify-content: center;
    align-items: center;
    /* padding: 20px 60px 0px 60px; */
}
.huebits-homepages1-h1,
.homepagehubits-for-students-p {
    color: rgb(5, 38, 89);
    padding: 0px 10px;
}

.huebits-homepages1-h1 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    padding-block-start: 25px;
}

.huebits-homepages1-h2 {
    font-weight: 600;
    font-size: 24px;
    color: rgb(5, 38, 89);
    text-align: center;
    margin: 20px 0px 40px 0px;
}

.students-icon__users-huebits {
    width: 30px;
    height: 30px;
    font-size: 20px;
    color: rgb(0, 0, 0);
    margin-left: 20px;
}

.students-icon__users-empower {
    width: 40px;
    height: 40px;
    font-size: 20px;
}

.huebits-homepages1-students-flex-huebits,
.huebits-homepages1-companies-flex-huebits {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
}

.huebits-homepages1-span {
    margin: 0px 14px 0px 14px;
}

.huebits-homepages1-span span {
    margin: 0px 20px 0px 20px;
}

.huebits-homepages1-companies-flex-huebits {
    margin-left: -40px;
}

.homepage-vertical-line {
    margin-left: 40px;
    border: 2px solid rgb(5, 38, 89);
    background-color: rgb(5, 38, 89);
    height: 320px;
    width: 4px;
    margin-top: -10px;
}

.homepage-companies-vertical-line {
    margin-left: 40px;
    border: 2px solid rgb(5, 38, 89);
    background-color: rgb(5, 38, 89);
    height: 220px;
    width: 4px;
    margin-top: -10px;
}

.students-Organizationsul-huebits-homepages,
.students-Organizationsul-huebits-homepage {
    width: 280px;
    height: auto;
    background-color: rgb(5, 38, 89);
    border-radius: 20px;
    margin-left: 40px;
    margin-bottom: 20px;
}

.huebits-homepages1-span {
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 20px 10px;
}

.students-Organizationsul-huebits-homepages {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 60px;
    border-top-left-radius: 60px;
}

.students-Organizationsul-huebits-homepage {
    border-top-left-radius: 0px;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
}

.huebitsimage,
.huebitsimage-1-companies-image {
    margin-bottom: 10px;
    animation-name: pulseAnimation;
}

.huebitsimage {
    width: 500px;
    height: 400px;
    margin-right: 60px;
    border-radius: 10px;
}

.huebitsimage-1-companies-image {
    width: 500px;
    height: 400px;
    margin-left: 100px;
    border-radius: 10px;
}

.homepagehubits-for-students-courses-projects-image1 {
    width: 30px;
    height: 30px;
    text-align: start;
}

.Organizationsul-huebits {
    padding-left: 10px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.Organizations-li {
    margin-right: 20px;
    list-style: none;
    margin-top: 120px;
}

.homepagehubits-for-students-color,
.homepagehubits-for-companies-color {
    /* background-color: rgb(255, 255, 255); */
    margin-left: auto;
    margin-right: auto;
    width: auto;
    height: auto;
    margin-top: 40px;
    padding: 40px 0px 20px 0px;
}

.homepagehubits-for-students-p {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    padding-bottom: 10px;
}

.homepagehubits-for-students-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.homepage-internet-of-things-images {
    border-radius: 10px;
    margin: 0px 20px 25px 20px;
    width: 160px;
    height: 160px;
    padding: 20px 0px 20px 0px;
    animation: borderstudentsAnimations 5s infinite;
    border-width: 4px;
    border-style: solid;
    background-color: rgb(255, 255, 255);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.homepage-internet-of-things-images:hover{
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}

@keyframes borderstudentsAnimations {
    20% {
        border-left-color: rgb(1, 57, 113);
        border-right-color: #fcf9fb;
        border-top-color: #fefcfd;
        border-bottom-color: #fefcfd;
    }
    40% {
        border-top-color: rgb(1, 57, 113);
        border-left-color: #f8f8f8;
        border-right-color: #fffdfe;
        border-bottom-color: #fefcfd;
    }
    60% {
        border-right-color: rgb(1, 57, 113);
        border-left-color: #ffffff;
        border-top-color: #ffffff;
        border-bottom-color: #fefcfd;
    }
    80% {
        border-bottom-color: rgb(1, 57, 113);
        border-right-color: rgb(253, 253, 253);
        border-left-color: #ffffff;
        border-top-color: #ffffff;
    }
}

.homepage-internet-images-of-things-images {
    border-radius: 10px;
    margin: 0px 14px 25px 20px;
    width: 160px;
    height: 160px;
    padding: 20px 4px 20px 4px;
    border-bottom: 10px solid rgb(5, 38, 89);
    box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.5);
    background-color: rgb(255, 255, 255);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.homepage-internet-images-of-things-images:hover{
    transform: scale(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
    cursor: pointer;
}
.homepagehubits-for-students-p1,
.homepagehubits-for-students-p2,
.homepagehubits-for-students-p3,
.homepagehubits-for-students-p4,
.homepagehubits-for-students-p5,
.homepagehubits-for-students-p6 {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5555555556;
    text-align: center;
    padding: 10px 0px 10px 0px;
    transition: color 0.3s ease;
}

.homepagehubits-for-students-courses-projects {
    margin-top: 40px;
}

.homepagehubits-for-companies-courses-projectses {
    display: flex;
    padding: 2px 0px 25px 0px;
    margin-top: 40px;
}

.homepage-internet-of-things,
.homepage-embedded,
.homepage-machine-learning,
.homepage-cyber-security,
.homepage-generativeai,
.homepage-robotics-and-automation {
    width: 60px;
    height: auto;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    align-items: center;
    justify-content: center;
    margin: 0px 50px 0px 50px;
    padding: 10px;
    box-shadow: 0 0px 4px rgb(115, 132, 165), 0 40px 40px rgba(115, 132, 165, 0.1);
}

.homepagehubits-for-students-courses-students-projects1 {
    background-color: #4489f8;
    padding: 40px 80px;
}

.homepagehubits-for-students-courses-projects1 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    gap: 10px;
    padding: 10px;
}

.homepagehubits-for-students-courses-projects1 p {
    font-weight: 700;
    font-size: 1.6rem;
    text-align: center;
    color: rgb(255, 255, 255);
}

.homepagehubits-for-students-courses-hr1,
.homepagehubits-for-students-courses-hr2,
.homepagehubits-for-students-courses-hr3 {
    border: 2px solid rgb(0, 0, 0);
    height: 60px;
    margin: 20px 0px 20px 10px;
}

.homepagehubits-for-students-courses-hr1 {
    margin: 0px 10px 0px 10px;
}

.homepagehubits-for-students-courses-hr2 {
    margin-left: 20px;
}

.homepagehubits-for-students-courses-pspan1,
.homepagehubits-for-students-courses-pspan {
    font-size: 2rem;
    font-weight: 900;
    background-image: linear-gradient(135deg, rgb(255, 255, 255), rgb(255, 255, 255));
    -webkit-background-clip: text;
    color: transparent;
}

.homepagehubits-for-companies-courses-p1,
.homepagehubits-for-companies-courses-p2,
.homepagehubits-for-companies-courses-p3,
.homepagehubits-for-companies-courses-p4 {
    font-weight: 700;
    font-size: 20px;
    color: rgb(2, 41, 96);
}

.Organizationsul-huebits-color {
    padding-top: 20px;
}

.homepagehubits-for-companies-courses-projectses {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 140px;
}
.homepagehubits-for-companies-courses-projectses-image{
    width: 500px;
    height: 400px;
}
.homepage-experience-learners {
    text-align: center;
    padding: 20px 0px 80px 0px;
    background-color: rgb(255, 255, 255);
}

.huebits-experience-homepages1-h1 {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    padding-bottom: 10px;
    background-image: linear-gradient(135deg, rgba(66, 79, 97, 0.8), rgba(5, 38, 89, 0.8) 50%, rgba(112, 162, 233, 0.8));
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media (max-width: 480px) {
    .homepagecolores {
        block-size: 92%;
    }
    /* .homepagecolores-background-flex {
        padding-left: 20px;
    }
    .homepagecolores-background-flex ul {
        padding: 40px 0px 100px 0px;
    }
    .homepagecolores-background-flex {
        flex-direction: column;
        height: auto;
    }
    .huebits-homepages-spark-spark {
        padding: 20px 20px 0px 20px;
        font-size: 34px;
        text-align: center;
        margin-left: 0px;
    }
    .homepage-students-companies-buttonget {
        flex-direction: column;
        margin: 10px 0px 0px 0px;
    }
    .homepage-students-buttonget-1:hover {
        transform: scale(1.01);
    }
    .homepage-students-buttonget-1 {
        margin-right: 20px;
    }
    .homepage-students-buttonget-1 {
        margin-top: 20px;
        border-radius: 0px;
    } */
    .homepagecolores-background-flex {
        padding-left: 20px;
        height: auto;
        padding: 40px 20px 80px 0px;
    }

    .huebits-homepages-spark-spark {
        font-size: 30px;
        width: 100%;
        padding-top: 0px;
    }

    .huebits-homepages-spark-spark-p {
        font-size: 1rem;
        width: 100%;
    }
    .huebits-homepages1-h1 {
        font-size: 24px;
    }
    .huebits-homepages1-future {
        font-size: 24px;
    }
    .huebits-homepages1-students-flex-huebits,
    .huebits-homepages1-companies-flex-huebits {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        width: 300px;
        height: auto;
        padding: 10px;
        margin-top: 40px;
    }
    .huebitsimage,
    .huebitsimage-1-companies-image {
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .homepage-vertical-line,
    .homepage-companies-vertical-line {
        height: 0px;
        width: 0px;
        margin-top: 0px;
    }
    .students-Organizationsul-huebits-homepages,
    .students-Organizationsul-huebits-homepage {
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .huebits-homepages1-span {
        font-size: 1rem;
    }
    .huebits-homepages1-span span {
        margin: 0px;
    }
    .huebitsimage-1-companies-image {
        margin-left: 0px;
    }
    .homepagehubits-for-students-color,
    .homepagehubits-for-companies-color {
        margin-left: auto;
        margin-right: auto;
        width: auto;
        padding: 10px;
    }
    .homepagehubits-for-students-p {
        text-align: center;
        font-size: 24px;
    }
    .students-Organizationsul-huebits {
        margin: 20px 0px 0px 0px;
    }
    .homepagehubits-for-students-courses-projects1 {
        margin-left: auto;
        margin-right: auto;
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
    }
    .homepagehubits-for-students-div,
    .homepagehubits-for-students-div1 {
        margin-left: 10px;
        padding-left: 4px;
    }
    .homepage-internet-of-things-images,
    .homepage-internet-images-of-things-images {
        width: 130px;
        height: 160px;
        margin: 20px 10px 0px 10px;
    }
    .homepage-internet-of-things,
    .homepage-embedded,
    .homepage-machine-learning,
    .homepage-cyber-security,
    .homepage-generativeai,
    .homepage-robotics-and-automation {
        width: 35%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .homepagehubits-for-students-div p,
    .homepagehubits-for-students-div1 p,
    .homepagehubits-for-students-courses-projects1 p {
        font-size: 1rem;
        margin: 0px;
    }
    .homepagehubits-for-students-courses-students-projects1 {
        padding: 10px;
    }
    .homepagehubits-for-students-courses-projects1 {
        justify-content: center;
        margin: 0px 10px 0px 10px;
    }
    .homepagehubits-for-students-courses-pspan1,
    .homepagehubits-for-students-courses-pspan {
        font-size: 1.6rem;
        margin-top: 40px;
    }
    .homepagehubits-for-companies-courses-projectses {
        gap: 10px;
        margin-left: 10px;
        margin-right: 10px;
    }
    .homepagehubits-for-companies-courses-projectses-image{
        width: 300px;
        height: 250px;
    }
    .homepage-experience-learners {
        text-align: center;
        padding: 20px 0px 80px 0px;
        background-color: rgb(255, 255, 255);
    }
    .homepagehubits-for-students-courses-hr1,
    .homepagehubits-for-students-courses-hr2,
    .homepagehubits-for-students-courses-hr3 {
        border: 0px solid rgb(111, 110, 110);
        height: 0px;
        margin: 0px;
    }
    .Organizations-li {
        margin-top: 0px;
        padding: 0px;
    }
    .homepagehubits-for-companies-courses-projectses {
        flex-direction: column;
        padding: 2px 0px 40px 0px;
        margin-top: 20px;
    }
    .Organizationsul-huebits-color {
        padding-top: 0px;
    }
    .homepagehubits-for-companies-courses-projectses {
        margin-left: auto;
        margin-right: auto;
    }
    .homepagehubits-for-companies-courses-projects1 {
        width: 300px;
        margin: 40px 0px 0px 10px;
    }
     
    .huebits-experience-homepages1-h1 {
        font-size: 24px;
    }
    .huebits-homepages1-review-p {
        font-size: 1.2rem;
    }
    .homepagehubits-for-companies-courses-projects,
    .homepagehubits-for-companies-courses-projects1 {
        padding: 10px;
        border-radius: 15px;
        margin: 0px 10px 20px 10px;
    }
    .homepagehubits-for-companies-courses-p1,
    .homepagehubits-for-companies-courses-p2,
    .homepagehubits-for-companies-courses-p3,
    .homepagehubits-for-companies-courses-p4 {
        font-size: 1rem;
        padding: 0px 10px 0px 10px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .homepagecolores {
        block-size: 93%;
    }
    .homepagecolores-background-flex {
        flex-direction: column;
        height: 550px;
        padding: 100px 0px 80px 0px;
    }
    
    .huebits-homepages-spark-spark {
        font-size: 50px;
        text-align: start;
        padding-top: 0px;
    }
    .huebits-homepages-spark-spark-p{
        text-align: start;
        font-size: 1.4rem;
    }
    /* .homepagecolores-background-flex {
        padding-left: 50px;
    }

    .huebits-homepages-spark-spark {
        font-size: 46px;
        width: 400px;
        padding-top: 160px;
    }

    .huebits-homepages-spark-spark-p {
        font-size: 1.1rem;
    } */
    .huebits-homepages-students-color {
        padding-top: 20px;
    }
    .huebits-homepages1-students-flex-huebits {
        font-size: 1.3rem;
    }
    .students-Organizationsul-huebits-homepages,
    .students-Organizationsul-huebits-homepage {
        margin-left: 0px;
    }
    .homepage-vertical-line,
    .homepage-companies-vertical-line {
        width: 0px;
        height: 0px;
    }
    .huebits-homepages1-span span {
        margin: 0px;
    }
    .students-Organizationsul-huebits-homepages {
        border-radius: 15px;
    }
    .students-Organizationsul-huebits-homepage {
        border-radius: 15px;
    }
    .homepage-internet-of-things-images,
    .homepage-internet-images-of-things-images {
        border-radius: 10px;
        margin: 20px;
        width: 160px;
        height: 200px;
    }
    .huebitsimage,
    .huebitsimage-1-companies-image {
        width: 350px;
        height: 400px;
    }
    .homepagehubits-for-students-color,
    .homepagehubits-for-companies-color {
        margin-left: auto;
        margin-right: auto;
        width: auto;
        padding: 10px;
    }
    .homepage-internet-of-things,
    .homepage-embedded,
    .homepage-machine-learning,
    .homepage-cyber-security,
    .homepage-generativeai,
    .homepage-robotics-and-automation {
        margin-bottom: 10px;
    }
    .homepagehubits-for-students-div,
    .homepagehubits-for-students-div1 {
        padding: 0px 10px 0px 0px;
        margin: 0px 10px 0px 10px;
    }
    .homepagehubits-for-students-div p,
    .homepagehubits-for-students-div1 p,
    .homepagehubits-for-students-courses-projects1 p {
        text-align: center;
        margin-left: 10px;
        padding: 4px;
    }
    .homepagehubits-for-students-courses-projects1 p {
        font-size: 1.2rem;
    }
    .homepagehubits-for-students-courses-projects1 {
        margin-left: auto;
        margin-right: auto;
        gap: 10px;
    }
    .homepagehubits-for-students-courses-hr1,
    .homepagehubits-for-students-courses-hr2,
    .homepagehubits-for-students-courses-hr3 {
        height: 60px;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: auto;
        margin-left: auto;
    }
    .homepagehubits-for-students-courses-pspan1,
    .homepagehubits-for-students-courses-pspan {
        font-size: 1.6rem;
    }
    .homepagehubits-for-companies-courses-projects1,
    .homepagehubits-for-students-courses-btn,
    .homepagehubits-for-companies-courses-btn {
        margin-left: 40px;
    }
    .homepagehubits-for-companies-courses-projectses {
        flex-wrap: wrap;
    }
    .homepagehubits-for-companies-courses-projects1 {
        width: 90%;
        margin-right: 40px;
    }
    .huebits-homepages1-huebits-solving {
        font-size: 30px;
        padding-top: 60px;
    }
    .huebits-homepages1-success {
        font-size: 1.4rem;
        text-align: start;
        margin: 0px 80px 0px 140px;
    }
    .hue-images-img {
        width: 100%;
        width: 375px;
        height: 750px;
        margin: 60px 120px 20px 0px;
    }
    .huebits-homepages1-h1 {
        font-size: 25px;
        margin-left: 10px;
        text-align: center;
    }
}