.organizations-popup-background-image {
    position: relative; /* Enables the use of the pseudo-element */
    height: 91%;
    background-image: url('../../assets/dashboard-images/Companiesimages/4.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow-x: hidden;
    overflow-y: auto;
}
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1); /* Slight zoom */
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0.8;
    }
}
.organizations-popup-background-image::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(36, 68, 116, 0.8); /* Semi-transparent overlay with #052659 */
    z-index: 1; /* Places it behind child elements */
    animation: pulse 4s infinite; /* Animation applied */
}

.organizations-popup-background-image > * {
    position: relative;
    z-index: 2; /* Ensures child content is above the overlay */
}

.organization-logout{
    margin-top: -10px;
}

.compaines-homepage-buttonget-1 {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-left: 6px;
    width: 220px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
    padding: 10px;
}

.compaines-page-postaprojects {
    margin-top: 300px;
    margin-left: 650px;
}

.compaines-project-profiles-p {
    font-size: 40px;
    font-weight: 700;
    line-height: initial;
}

.compaines-project-profiles-p,
.compaines-active,
.compaines-pending,
.compaines-completed,
.compaines-profile-h1,
.compaines-profile-since {
    font-family: "Poppins variant15", Tofu;
    font-style: normal;
    font-stretch: normal;
    text-align: start;
    margin-bottom: 10px;
    padding-top: 10px;
    color: black;
}

.compaines-active,
.compaines-pending,
.compaines-completed {
    font-size: 30px;
    margin-left: 20px;
    padding-top: 40px;
    color: #5483B3;
    font-weight: 700;
    line-height: initial;
}

.compaines-dashboards-profiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.compaines-dashboard-flex {
    display: flex;
    list-style: none;
    padding-top: 10px;
}

.compaines-dashboard-flex a {
    text-decoration: none;
}

.compaines-project-profiles {
    margin-left: 40px;
    padding-left: 20px;
}

.compaines-project-profiles-active {
    background-color: rgb(224, 222, 222);
    width: 800px;
    height: auto;
    padding-left: 40px;
    border-radius: 10px;
    padding-bottom: 20px;
    padding-top: 10px;
}

.compaines-dashboard {
    display: flex;
    flex-wrap: wrap;
}

.companies-dashboard-project {
    list-style: none;
}

.compaines-pending,
.compaines-completed {
    margin-left: 220px;
}

.companies-dashboard-projects {
    margin-left: -10px;
}

.companies-dashboard-skills,
.companies-dashboard-projects,
.companies-dashboard-title,
.companies-dashboard-textarea,
.companies-dashboard-types,
.companies-dashboard-path {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-left: -20px;
}

.compaines-profile-logout {
    display: flex;
    flex-wrap: wrap;
}

.compaines-dashboard-profile-logout {
    display: flex;
}

.compaines-profile-h1 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
}

.compaines-logout {
    width: 80px;
    margin-left: 20px;
    height: 40px;
    margin-top: 20px;
    border: none;
    background-color: #42597c;
    color: white;
    border-radius: 10px;
}

.dashboard-companies-postprojects {
    margin-top: 20px;
}

.compaines-profile {
    margin-left: 40px;
    margin-top: 10px;
    color: white;
    height: 525px;
    width: 380px;
    padding: 20px;
    margin-right: 100px;
    background-color: rgb(224, 222, 222);
    border-radius: 10px;
}

.compaines-profilepic1 {
    cursor: pointer;
}

.compaines-profilepic1 {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
    border: 2px solid #5483B3;
    margin-left: 120px;
    margin-top: 20px;
}

.compaines-firstname,
.form-compaines-firstname {
    font-family: var(--font-stack-heading);
    line-height: 1.2;
    letter-spacing: 0;
    font-size: 1.0rem;
    color: rgb(0, 0, 0);
}

.compaines-firstname {
    font-weight: 600;
}

.form-compaines-firstname {
    font-weight: 400;
    width: auto;
    height: auto;
    margin-bottom: 10px;
    padding: 10px;
}

.compaines-profile-since {
    line-height: 0.5rem;
    margin-left: 60px;
}

.compaines-profile-update {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-family: var(--font-stack-heading);
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0;
    font-size: 1.4rem;
    margin-left: 70px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.compaines-profile-update:hover {
    background: linear-gradient(135deg, #052659, #5483B3);
}

@media (max-width: 480px) {
    .compaines-project-profiles-p {
        font-size: 1.8rem;
        margin-left: 0px;
        padding: 0px;
        margin: 10px 20px 0px 0px;
    }
    .compaines-active,
    .compaines-pending,
    .compaines-completed {
        font-size: 1.4rem;
        margin-left: 0px;
        padding-top: 20px;
    }
    .compaines-dashboards-profiles {
        flex-direction: column;
        align-items: center;
    }
    .compaines-dashboard-flex {
        flex-direction: column;
        padding-top: 10px;
    }
    .compaines-dashboard-flex a {
        text-decoration: none;
    }
    .compaines-project-profiles {
        margin-left: 0px;
        padding-left: 0px;
    }
    .compaines-project-profiles-active {
        width: 300px;
        height: auto;
        align-items: center;
        border-radius: 0px;
        margin-top: 10px;
        padding: 10px;
        margin-left: 0px;
    }
    .compaines-dashboard {
        display: flex;
        gap: 4px;
    }
    .compaines-pending,
    .compaines-completed {
        margin-left: 20px;
    }
    .companies-dashboard-titles,
    .companies-dashboard-skills {
        flex-wrap: wrap;
    }
    .compaines-profile-logout {
        flex-direction: column;
    }
    .compaines-dashboard-profile-logout {
        display: flex;
        gap: 20px;
    }
    .compaines-profile-h1 {
        margin-top: 20px;
        font-size: 1.6rem;
        font-weight: 700;
        align-items: center;
        padding: 0px;
    }
    .compaines-logout {
        border-radius: 0px;
        margin-left: 0px;
    }
    .dashboard-companies-postprojects {
        margin-top: 20px;
    }
    .compaines-profile {
        width: 300px;
        height: auto;
        padding: 20px;
        align-items: center;
        border-radius: 0px;
        padding-bottom: 40px;
        margin-left: 0px;
    }
    .compaines-profilepic1 {
        margin-left: 80px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .compaines-profile-since {
        line-height: 0.5rem;
        margin-left: 8px;
    }
    .compaines-profile-update {
        width: 200px;
        height: 40px;
        border-radius: 0px;
        margin-left: 30px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .compaines-page-postaprojects {
        margin-top: 300px;
        margin-left: 300px;
    }
    .compaines-active,
    .compaines-pending,
    .compaines-completed {
        margin-left: 20px;
    }
    .compaines-dashboard-flex {
        flex-direction: column;
        list-style: none;
        padding-top: 10px;
    }
    .compaines-project-profiles {
        margin-left: 20px;
    }
    .compaines-project-profiles-active {
        width: 600px;
        padding-left: 20px;
    }
    .compaines-pending,
    .compaines-completed {
        margin-left: 114px;
    }
    .compaines-profile-logout {
        margin-left: 160px;
    }
    .compaines-profile {
        margin-left: 180px;
    }
    .compaines-profile-update {
        margin-left: 80px;
    }
}