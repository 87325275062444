/* .homepage-footers-images {
    background-image: url("../../assets/Foot.png");
    background-color: rgba(2, 2, 2, 0.50);
    background-blend-mode: overlay;
} */

.homepage-footers-images {
   background-color: rgb(1, 18, 35);
   padding: 40px 20px 20px 20px;
   color: #ffff;
}

.homepage-footer-links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 100px;
    color: #ffff;
    text-align: left;
    padding: 10px 10px 40px 10px;
}
.footer-linkh1-ul ul{
    list-style: none;
    line-height: 2rem;
}
.footer-linkh1-ul ul h2{
    list-style: none;
    line-height: 2rem;
    font-size: 1.2rem;
    font-weight: 700;
}
.footer-linkh1-ul ul li{
    font-size: 16px;
}
.footer-linkh1-ul a{
    text-decoration: none;
    color: #ffff;
}
.footer-linkh1-ul a:hover{
    text-decoration: underline;
}
.footer-hompage-image {
    width: 200px;
    height: 50px;
    margin-top: 0px;
}

.homepage-footer-hr {
    border: 2px solid white;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.footer-social {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 80px;
}

.footer-social-icons {
    margin-left: auto;
    padding-right: 70px;
}

.social-icon,
.homepage-social-icon-link {
    margin-left: 10px;
    width: 35px;
    height: 35px;
    color: rgb(5, 38, 89);
    border-radius: 50%;
    padding: 8px;
    background-color: rgb(255, 255, 255);
    gap: 10px;
}

.social-icon:hover,
.homepage-social-icon-link:hover {
    transform: scale(1.1);
}

.homepage-social-icon-link {
    color: rgb(5, 38, 89) !important;
    text-decoration: none;
    background-color: rgb(255, 255, 255);
}
.homepage-footer-copyrightp {
    font-size: 16px;
    font-weight: 400;
}

@media (max-width: 480px) {
    .homepage-footers-images {
        margin-top: -10px;
     }
    .homepage-footer,
    .homepage-footers {
        padding: 20px 10px 0px 10px;
        text-align: center;
    }
    .homepage-footer {
        margin-top: 0px;
    }
     .homepage-footer-links{
         gap: 10px;
     }
    .footer-hompage-image {
        width: 150px;
        height: auto;
        margin-left: 0;
        margin-top: 20px;
    }
    .homepage-footer-hr {
        margin: 10px auto;
        width: 100%;
    }
    .footer-social {
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-left: 0;
    }
    .footer-social-icons {
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .social-icon,
    .homepage-social-icon-link {
        width: 30px;
        height: 30px;
        margin: 5px;
        padding: 6px;
    }
}

@media (min-width: 480px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .homepage-footer {
        padding: 40px 0px 0px 0px;
        justify-content: center;
    }
    .homepage-footers {
        padding: 40px 0px 0px 0px;
    }
    .homepage-footers-images {
        background-color: rgb(1, 18, 35);
        padding: 40px 20px 20px 20px;
        color: #ffff;
     }
     
     .homepage-footer-links{
         gap: 60px;
     }
    .footer-hompage-image {
        width: 222px;
        height: 54px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        padding: 0px 40px 0px 0px;
    }
    .homepage-footer-hr {
        margin: 10px auto;
        width: 100%;
    }
    .footer-social {
        justify-content: start;
        align-items: start;
        padding-top: 10px;
        padding-bottom: 10px;
    }
}