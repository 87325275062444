.organization-iot-tech-talent-banners1,
.organization-data-science-tech-talent-banners1,
.organization-iot-tech-talent-banner2,
.organization-iot-tech-talent-banner4,
.organization-iot-tech-talent-banner6,
.organization-iot-tech-talent-banner7 {
  background-size: cover;
  background-position: center;
  flex-wrap: wrap;
  position: relative;
  height: 90vh;
  color: #fff;
}
.organization-iot-tech-talent-banners1 {
  background-image: url('../../assets/companiesimages/InternetofThings1.png');
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
}

.organization-data-science-tech-talent-banners1{
  background-image: url('../../assets/companiesimages/InternetofThings2.png');
  padding: 0 20px;
  display: flex;
  flex-wrap: wrap;
}

.organization-iot-tech-talent-banners1::before,
.organization-data-science-tech-talent-banners1::before,
.organization-iot-tech-talent-banner2::before,
.organization-iot-tech-talent-banner6::before,
.organization-iot-tech-talent-banner7::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(5, 38, 89, 0.4);
  z-index: 1; 
}

.organization-iot-tech-talent-banners1 > *,
.organization-data-science-tech-talent-banners1 > *,
.organization-iot-tech-talent-banner2 > *,
.organization-iot-tech-talent-banner6 > *,
.organization-iot-tech-talent-banner7 > * {
  position: relative;
  z-index: 2;
}
.organization-iot-tech-talent-banner1 {
  max-width: 720px; 
  margin: 20vh 0px 0px 100px;
}

.organization-iot-tech-talent-banner1 h1 {
  font-weight: 900;
  font-size:60px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
}

.organization-iot-tech-talent-banner1 p {
  font-size: 1.4rem; 
  margin-bottom: 30px;
  line-height: 1.5;
}

.organization-iot-tech-talent-banner1-btn,
.organization-iot-tech-talent-banner2-btn {
  background: linear-gradient(135deg, #145faa, #052659);
  color: white;
  flex-wrap: wrap;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease;
  inline-size: auto;
  block-size: auto;
  padding: 6px 20px;
  border-radius: 20px;
  font-weight: 700;
  font-size: 20px;
  line-height: 1.25;
  margin: 10px 20px 20px 10px;
}

.organization-iot-tech-talent-banner1-btn:hover,
.organization-iot-tech-talent-banner2-btn:hover {
  background: linear-gradient(135deg, #052659, #0e57a0);
}

.organization-iot-tech-talent-banner2 {
  padding: 20px 10px 0px 10px;
  background-image: url('../../assets/companiesimages/InternetofThings3.png');
}
  .organization-iot-tech-talent-banner2 h1{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: rgb(255, 255, 255);
  }
  .organization-iot-tech-talent-banner2 p{
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: white;
    text-align: start;
    inline-size: 450px;
  }
  .organization-iot-tech-talent-banner2-cards{
    display: flex;
    flex-wrap: wrap;
    margin: 70px 0px 0px 250px;
  }
  .organization-iot-tech-talent-banner2-btn{
    padding: 10px 20px;
  }
  .organization-iot-tech-talent-banner3 {
    text-align: center;
    height: auto;
    background-color: #1c1c1c;
    color: #ffffff;
    padding: 40px 20px;
  }

  .organization-iot-tech-talent-banner3 h1 {
    font-size: 36px;
    font-weight: 700;
    padding-bottom: 20px;
  }
  
  .organization-iot-tech-talent-banner3-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
    padding-top: 20px;
    margin-bottom: 1.5rem;
  }
  
  .animated-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 220px;
    height: 220px;
    animation: slideUp 1s ease-in-out;
  }
  
  .animated-card:hover {
    transform: translateY(-5px);
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.2);
  }

  .organization-iot-tech-talent-banner3-card-image{
    width: 160px;
    height: 100px;
    align-items: center;
  }
    
  .organization-iot-tech-talent-banner3-card p {
    font-size: 1rem;
    font-weight: 700;
    color: #043885;
    text-align: center;
  }
  .organization-iot-tech-talent-banner4{
    padding: 60px 20px 60px 20px;
    background-image: url('../../assets/companiesimages/InternetofThings4.png');
    height: auto;
  }
  .organization-iot-tech-talent-banner4-h1{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #043885;
  }
  .organization-iot-tech-talent-banner4-div1-p{
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    text-align: center;
    align-items: center;
    margin: auto;
    width: 76%;
  }
  .organization-iot-tech-talent-banner4-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 40px;
    padding: 40px 20px;
}

.organization-iot-tech-talent-banner4-card > div {
    background-color: rgba(255, 255, 255, 0.8); 
    border-radius: 15px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 350px;
}

.organization-iot-tech-talent-banner4-card > div:hover {
    transform: translateY(-10px); 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15); 
}

.organization-iot-tech-talent-banner4-card p {
    font-size: 20px;
    font-weight: 700;
    color: #052659;
    margin: 0;
    text-transform: uppercase;
}

.organization-iot-tech-talent-banner5 {
  background-color: #f9f9f9;
  padding: 40px 20px;
  text-align: center;
}

.organization-iot-tech-talent-banner5 h1 {
  font-size: 36px;
  font-weight: 700;
  color: #052659;
  margin-bottom: 40px;
}

.organization-iot-tech-talent-banner5-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.organization-iot-tech-talent-banner5-1 {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0px;
  margin: 20px;
  width: calc(45% - 20px);
  min-width: 400px; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}
.organization-iot-tech-talent-banners5-1 {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0px;
  margin: 20px;
  width: calc(40% - 20px);
  min-width: 92%; 
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}
.organization-iot-tech-talent-banners5-1:hover,
.organization-iot-tech-talent-banner5-1:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.organization-iot-tech-talent-banners5-1 h2,
.organization-iot-tech-talent-banner5-1 h2 {
  font-size: 20px;
  font-weight: 600;
  color: #052659;
  padding: 20px 0px 0px 0px;
  margin-bottom: 15px;
}

.organization-iot-tech-talent-banner5-logos {
  display: inline-block;
  width: 84px;
  height: 84px;
  margin: 20px;
  transition: transform 0.3s;
}

.organization-iot-tech-talent-banner5-logos:hover {
  transform: scale(1.1);
}

.organization-iot-tech-talent-banner6{
  padding: 60px 20px 40px 20px;
  background-image: url('../../assets/companiesimages/InternetofThings5.png');
  block-size: auto;
}
.organization-iot-tech-talent-banner6 h1{
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #ffff;
}
.organization-iot-tech-talent-banner6 p{
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.5;
    color: #ffff;
    text-align: center;
    align-items: center;
    margin: auto;
    inline-size: 76%;
    padding-top: 10px;
}
.organization-iot-tech-talent-banner6-steps {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  gap: 0px;
  margin-top: 60px;
}

.organization-iot-tech-talent-banner6-step {
  text-align: center;
  max-width: 200px;
  margin-right: 10px;
  /* flex: 1 1 calc(33.33% - 10px); Ensures 3 items per row with proper spacing */
}

@keyframes bounce {
  0%, 100% {
    transform: rotate(45deg) translateY(0);
  }
  50% {
    transform: rotate(45deg) translateY(-15px);
  }
}

.organization-iot-tech-talent-banner6-diamond {
  position: relative;
  margin: 40px auto;
  width: 70px;
  height: 70px;
  background-color: #052659;
  color: white;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border: 4px solid white;
  transform: rotate(45deg);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  animation: bounce 2s infinite ease-in-out;
}

.organization-iot-tech-talent-banner6-diamond span {
  transform: rotate(-45deg);
  display: block;
}

.organization-iot-tech-talent-banner6-diamond::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.1);
  transform: translate(-50%, -50%) rotate(-45deg);
  border-radius: 5px;
}

.organization-iot-tech-talent-banner6-step p {
  font-size: 16px;
  font-weight: 900;
  color: #ffff;
  margin-top: 10px;
  text-align: center;
}

.organization-iot-tech-talent-banner7{
  padding: 60px 20px 10px 20px;
  background-image: url('../../assets/companiesimages/InternetofThings6.png');
  height: auto;
}
.organization-iot-tech-talent-banner7-h1{
  font-size: 36px;
  font-weight: 700;
  text-align: center;
  color: #ffff;
}
.organization-iot-tech-talent-banner7-p{
  justify-content: center;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5;
  color: #ffff;
  text-align: center;
  align-items: center;
  margin: auto;
  width: 76%;
  padding-top: 10px;
}
.organization-iot-tech-talent-banner7-contact {
  text-align: center;
  margin: 40px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.organization-iot-tech-talent-banner7-contact h1 {
  font-size: 30px;
  color: #ffffff;
  margin-bottom: 10px;
  font-weight: 900;
  text-shadow: 2px 2px 4px rgba(4, 68, 137, 0.7);
}

.organization-iot-tech-talent-banner7-contact p {
  font-size: 18px;
  color: #ffffff;
}

.organization-iot-tech-talent-banner7-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  margin: 80px auto;
}

.organization-iot-tech-talent-banner7-btn1 {
  text-align: center;
  flex: 1 1 300px;
  max-width: 300px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.2); 
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.organization-iot-tech-talent-banner7-btn1:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.organization-iot-tech-talent-banner7-btn1 button {
  background-color: #052659;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.organization-iot-tech-talent-banner7-btn1 button:hover {
  background-color: #043885;
}

.organization-iot-tech-talent-banner7-btn1 p {
  margin-top: 10px;
  font-size: 18px;
  color: #ffffff;
}

/* Icons */
.organization-iot-tech-talent-banner8{
  background-color: #ffff;
  height: auto;
  padding-top: 20px;
}
.organization-iot-tech-talent-banner8-p{
  color: #043885;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.organization-iot-tech-talent-banner8-1{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 140px;
  padding-bottom: 40px;
}
.organization-iot-tech-talent-banner8-cards{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.organization-iot-tech-talent-banner8-cards-image{
  width: 150px;
  height: 150px;
  margin-bottom: 20px;
}
.organization-iot-tech-talent-banner8-cards-Partners{
  width: 500px;
  height: 450px;
  border-radius: 20px;
}

@media (max-width: 480px) {
  .organization-iot-tech-talent-banners1,
  .organization-data-science-tech-talent-banners1,
  .organization-iot-tech-talent-banner2,
  .organization-iot-tech-talent-banner4,
  .organization-iot-tech-talent-banner6,
  .organization-iot-tech-talent-banner7 {
    height: 90vh;
  }
  .organization-iot-tech-talent-banners1::before,
  .organization-data-science-tech-talent-banners1::before,
  .organization-iot-tech-talent-banner2::before,
  .organization-iot-tech-talent-banner6::before,
  .organization-iot-tech-talent-banner7::before {
  background-color: rgba(0, 0, 0, 0.4);
}
  .organization-iot-tech-talent-banner1 {
    max-width: 100%;
    margin: 20px;
  }
  
  .organization-iot-tech-talent-banner1 h1 {
    font-size:40px;
    margin-top: 40px;
  }
  
  .organization-iot-tech-talent-banner1-btn,
  .organization-iot-tech-talent-banner2-btn {
    border-radius: 0px;
    margin: 20px;
  }
  .organization-iot-tech-talent-banner2 {
    padding: 20px;
    height: auto;
  }
    .organization-iot-tech-talent-banner2 h1{
      font-size: 28px;
      text-align: center;
    }
    .organization-iot-tech-talent-banner2 p{
      inline-size: auto;
    }
    .organization-iot-tech-talent-banner2-cards{
      margin: 20px;
    }
    .organization-iot-tech-talent-banner3 {
      height: 100%;
      padding: 20px 10px 40px 10px;
    }
  
    .organization-iot-tech-talent-banner3 h1 {
      font-size: 30px;
    }
    
    .organization-iot-tech-talent-banner3-card {
      gap: 40px;
    }
  
    .organization-iot-tech-talent-banner3-card-image{
      width: 200px;
      height: 150px;
      align-items: center;
    }
    .organization-iot-tech-talent-banner4{
      padding: 40px 20px;
      height: auto;
      margin-top: -20px;
    }
    .organization-iot-tech-talent-banner4-div1-p{
      width: 100%;
    }
    .organization-iot-tech-talent-banner4-card > div {
      padding: 10px;
  }
  .organization-iot-tech-talent-banner4-card p {
      font-size: 20px;
      line-height: 2rem;
  }
  .organization-iot-tech-talent-banner5 h1 {
    font-size: 30px;
  }
  .organization-iot-tech-talent-banner5-1 {
    margin: 20px;
    width: calc(40% - 20px);
    min-width: 350px; 
  }
  .organization-iot-tech-talent-banner6{
    padding: 40px 20px;
    height: auto;
  }
  .organization-iot-tech-talent-banner6 h1{
      font-size: 30px;
  }
  .organization-iot-tech-talent-banner6 p{
      inline-size: 100%;
      padding-top: 40px;
  }
  
  .organization-iot-tech-talent-banner6-step {
    max-width: 140px;
  }
  .organization-iot-tech-talent-banner6-diamond {
    width: 70px;
    height: 70px;
    gap: 20px;
  }
  .organization-iot-tech-talent-banner7{
    padding: 40px 20px;
    height: auto;
  }
  .organization-iot-tech-talent-banner7-h1{
    font-size: 30px;
  }
  .organization-iot-tech-talent-banner7-p{
    width: 100%;
  }
  .organization-iot-tech-talent-banner7-contact {
    margin: 20px auto;
    border-radius: 0px;
  }
  
  .organization-iot-tech-talent-banner7-btn1 {
    border-radius: 0px;
    margin-top: 10px;
  }
  /* Icons */
.organization-iot-tech-talent-banner8{
  height: auto;
  padding: 20px;
}
.organization-iot-tech-talent-banner8-p{
  font-size: 34px;
}
.organization-iot-tech-talent-banner8-1{
  gap: 20px;
  padding-bottom: 20px;
}
.organization-iot-tech-talent-banner8-cards-image{
  width: 100px;
  height: 100px;
}
.organization-iot-tech-talent-banner8-cards-Partners{
  justify-content: center;
  width: 300px;
  height: 300px;
  border-radius: 0px;
}
}

@media (min-width: 480px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
  .organization-iot-tech-talent-banners1,
  .organization-data-science-tech-talent-banners1,
  .organization-iot-tech-talent-banner2,
  .organization-iot-tech-talent-banner4,
  .organization-iot-tech-talent-banner6,
  .organization-iot-tech-talent-banner7 {
    height: auto;
    color: #fff;
  }

  .organization-iot-tech-talent-banner1 {
    max-width: 100%; 
    margin: 20px;
  }
  
  .organization-iot-tech-talent-banner1 h1 {
    padding: 40px 0px 0px 0px;
  }
  
  .organization-iot-tech-talent-banner1-btn,
  .organization-iot-tech-talent-banner2-btn {
    margin: 10px 20px 100px 10px;
  }
  
  .organization-iot-tech-talent-banner1-btn:hover,
  .organization-iot-tech-talent-banner2-btn:hover {
    background: linear-gradient(135deg, #052659, #0e57a0);
  }
  
  .organization-iot-tech-talent-banner2 {
    padding: 40px 20px;
  }
    .organization-iot-tech-talent-banner2 p{
      inline-size: 100%;
      padding-top: 40px;
    }
    .organization-iot-tech-talent-banner2-cards{
      display: flex;
      flex-wrap: wrap;
      margin: 40px 20px 20px 20px;
    }
    .organization-iot-tech-talent-banner2-btn{
      padding: 10px 20px;
    }
    .organization-iot-tech-talent-banner3 {
      height: auto;
      padding: 40px 20px 60px 20px;
    }
    
    .animated-card {
      margin-top: 20px;
    }
    .organization-iot-tech-talent-banner4{
      padding: 40px 20px 60px 20px;
    }
    .organization-iot-tech-talent-banner4-div1-p{
      width: 100%;
    }
  
  .organization-iot-tech-talent-banner4-card > div {
      height: 150px;
      width: 300px;
  }
  
  .organization-iot-tech-talent-banner5 {
    padding: 40px 20px;
  }
  .organization-iot-tech-talent-banner5-1 {
    width: calc(40% - 20px);
    min-width:auto; 
  }
}