.customizedscroling {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 695px;
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
}

.databigdata {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.datascience,
.bigdata {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 200px;
    height: 40px;
    border-radius: 10px;
    font-family: var(--font-stack-heading);
    font-weight: 700;
    line-height: 1.2;
    letter-spacing: 0;
    font-size: 20px;
    text-align: center;
    padding-bottom: 4px;
}

.bigdata {
    margin-left: 20px;
}

.datascience:hover,
.bigdata:hover {
    background: linear-gradient(135deg, #052659, #5483B3);
    transform: scale(1.1) translateY(-1px);
}

.students-datascience1 {
    margin-top: 20px;
    margin-left: 540px;
}

.students-datascience1-h1 {
    font-weight: 700;
    font-size: 40px;
    text-align: start;
    color: white;
}


/* Styles for phone screens */

@media (max-width: 480px) {
    .customizedscroling {
        height: 632px;
        padding-bottom: 20px;
    }
    .databigdata {
        flex-direction: column;
        margin-top: 20px;
    }
    .datascience,
    .bigdata {
        text-align: center;
        margin-top: 10px;
    }
    .bigdata {
        margin-left: 20px;
    }
    .datascience {
        margin-left: 20px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .customizedscroling {
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        height: 590px;
    }
    .databigdata {
        margin-top: 20px;
        display: flex;
    }
    .datascience,
    .bigdata {
        text-align: center;
        margin-top: 10px;
    }
    .bigdata {
        margin-left: 20px;
    }
    .datascience {
        margin-left: 20px;
    }
    form {
        display: block;
        margin-top: 0em;
    }
    fieldset {
        border-style: groove;
        border-color: rgb(192, 192, 192);
    }
    .customised {
        text-align: center;
        margin-left: 40px;
        font-size: 30px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
        margin-top: 30px;
    }
    .role1 {
        margin-right: 90px;
    }
    .role2 {
        margin-right: 20px;
    }
    .role3 {
        margin-right: 10px;
    }
    select {
        width: 380px;
    }
    .customised1 {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
        margin-top: 30px;
    }
    .datascienceLink-link.active {
        transform: translateX(-20px);
        color: black !important;
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .datascienceLink-link.active .customised {
        text-align: center;
        margin-left: 0px;
        font-size: 30px;
        font-weight: 400;
        font-family: 'Lato', sans-serif;
        margin-top: 0px;
    }
    .bigdataLink-link.active {
        transform: translateX(0px);
        margin-top: 6px;
    }
}