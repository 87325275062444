.huebits-iner-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 80px;
    margin: 20px auto;
}

.huebits-choose {
    padding: 30px;
    /* background-color: #fff; */
}

.homepagehubits-for-students {
    font-weight: 700;
    font-size: 26px;
    text-align: center;
    padding: 10px;
    color: rgb(5, 38, 89);
}

.huebits-inr2-3 {
    margin-top: 60px;
}

.huebits-homepages1-span-hue {
    color: #0377e3;
    font-size: 1.4rem;
    font-weight: 600;
    margin-left: 40px;
}

.huebits-homepages1-span-hue-faCheck{
    margin-right: 10px;
    color: rgb(5, 38, 89);
}

.huebits-homepages1-span-hue-hr {
    background-color: #616162;
    height: 1.5px;
    margin: 20px 40px 40px 40px;
}

.homepagehubits-for-students-courses-btn,
.homepagehubits-for-companies-courses-btn {
    align-items: start;
}

.homepagehubits-for-students-courses-btn {
    margin: 40px 0px 10px 110px;
}

.homepagehubits-for-companies-courses-btn {
    margin: 20px 0px 40px 0px;
}

.huebits-homepages {
    width: 450px;
    height: 350px;
    margin-top: 10px;
    border-radius: 20px;
}

@media (max-width: 480px) {
    .huebits-choose {
        padding: 40px 0 0;
    }
    .homepagehubits-for-students {
        font-size: 24px;
    }
    .huebits-inr2-3 {
        margin-top: 40px;
    }
    .huebits-homepages1-span-hue {
        font-size: 1.2rem;
        margin: 0 20px;
    }
    .huebits-homepages1-span-hue-hr {
        margin: 20px;
    }
    .huebits-homepages {
        width: 300px;
        height: auto;
        margin-top: 20px;
        border-radius: 0;
    }
    .homepagehubits-for-students-courses-btn {
        margin-top: 10px;
    }
    .homepagehubits-for-companies-courses-btn {
        margin: 40px 0px 20px 0px;
    }
}

@media (min-width: 481px) and (max-width: 1023px) {
    .huebits-iner-flex {
        margin: 10px auto;
    }
    .huebits-choose {
        padding: 20px;
    }
    .homepagehubits-for-students {
        font-size: 30px;
    }
    .huebits-inr2-3 {
        margin-top: 40px;
    }
    .huebits-homepages1-span-hue {
        margin-left: 0;
    }
    .huebits-homepages1-span-hue-hr {
        margin-left: 0;
    }
    .homepagehubits-for-students-courses-btn,
    .homepagehubits-for-companies-courses-btn {
        margin-top: 10px;
    }
    .huebits-homepages {
        width: auto;
        height: auto;
    }
}