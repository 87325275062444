.footerpage-top-buttons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 15px;
}

.footertoggle-button {
  padding: 12px 25px;
  margin: 0;
  border: 2px solid transparent;
  border-radius: 50px; /* Rounded buttons */
  cursor: pointer;
  background-color: #ffffff;
  color: #022d65;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.footertoggle-button:hover {
  background-color: #f0f8ff;
  border-color: #022d65;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.footertoggle-button.active {
  background-color: #022d65;
  color: #ffffff;
  border-color: #ffff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.footertoggle-button:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.6);
}

.terms-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    line-height: 1.6;
  }
  
  .terms-container h1 {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    color: #043885;
  }
  .terms-container p{
    font-size: 20px;
    font-weight: 400;
    color: #000000;
  }
  .terms-container h2 {
    font-size: 1.4rem;
    font-weight: 700;
    color: #022d65;
  }
  .terms-containers-div ul li{
    list-style: none;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 700;
    color: rgb(1, 16, 41);
  }
  .terms-containers-div ul li:hover{
    text-decoration: underline;
    color: #052659;
  }
  .terms-container h3,
  .terms-container h4 {
    margin-top: 10px;
    font-size: 1.1rem;
    color: #052659;
  }

  .terms-containers-ul li{
    font-size: 18px;
    font-weight: 400;
    color: #000000;
  }
  