.courses {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
}

.courses a {
    text-decoration: none;
    transition: color 0.3s ease-in-out, margin-left 0.3s ease-in-out;
    margin-left: 40px;
}

.active,
.favorite,
.launched {
    font-weight: 400;
    font-size: 1rem;
    color: #000000;
}

.active-link h1,
.favorite-link h1,
.launched-link h1 {
    border-bottom: 4px solid #5483B3;
    color: #000000;
    padding-bottom: 20px;
    margin-left: 4px;
}

.courseslist-courses,
.myactive-projectses-dashboard {
    width: 750px;
    margin-right: 20px;
    border-radius: 20px;
    margin-top: 20px;
}

.courseslistp-myactive-courses {
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    font-weight: 400;
}

.courseslist-p {
    font-size: 1.2rem;
    font-weight: 700;
    color: red;
}

.courseslist-myactive-courses,
.courseslist-completed-courses,
.courseslist-launched-courses {
    padding-bottom: 4px;
    margin-left: 43px;
}

.myactive,
.myfavorite,
.mylaunched {
    font-weight: 700;
    font-size: 46px;
    color: #000000;
}

.courseslist-course-row {
    display: flex;
    justify-content: start;
    margin-bottom: 20px;
}

.courseslist-course-box {
    padding: 10px 20px 20px 20px;
    margin: 20px 40px 0px 0px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 20px 40px rgba(255, 254, 254, 0.2), 0 0 20px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
}

.spinner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.spinner {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(0, 0, 0, 0.1);
    border-top: 5px solid #043885;
    /* Spinner color */
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media (max-width: 480px) {
    .students-courses {
        height: 636px;
        padding-bottom: 20px;
    }
    .courses {
        margin-bottom: 20px;
        margin-right: 20px;
    }
    .courses a {
        margin-left: 10px;
        margin-right: 20px;
    }
    .active,
    .favorite,
    .launched {
        font-weight: 700;
    }
    .active-link h1,
    .favorite-link h1,
    .launched-link h1 {
        padding-bottom: 10px;
    }
    .courseslist-courses,
    .myactive-projectses-dashboard {
        width: auto;
        margin-right: 20px;
    }
    .courseslist-myactive-courses,
    .courseslist-completed-courses,
    .courseslist-launched-courses {
        margin-left: 10px;
        margin-right: 10px;
    }
    .myactive,
    .myfavorite,
    .mylaunched {
        font-size: 30px;
    }
    .courseslist-course-row {
        flex-direction: column;
        justify-content: start;
        margin-bottom: 20px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .courseslist-courses,
    .myactive-projectses-dashboard {
        width: auto;
        margin: 10px 40px 0px 0px;
    }
}