.bestplanchoose-students,
.singlemembership-monthly-plan,
.singlemembership-inr,
.singlemembership-inr-inr1,
.Internet-of-Things-1-learning-paths,
.choosespan-students,
.singlemembership-month,
.singlemembership-mo,
.per-month {
    color: rgb(5, 38, 89);
}

.bestplanchoose-students {
    text-align: center;
    font-weight: 700;
    font-size: 30px;
    padding-top: 20px;
}

.students-inr2-3-lakh-flex {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;
}

.students-inr2-3-background,
.students-inr2-4-background {
    border-radius: 20px;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0), 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.5);
    width: 520px;
    margin: 40px 0px 60px 0px;
    background: linear-gradient(to right, rgb(239, 242, 247) 0%, rgba(215, 238, 252, 0.6) 25%, rgb(208, 228, 249) 50%, rgba(197, 231, 252, 0.6) 75%, rgb(255, 255, 255) 100%);
}

.students-inr2-4-background {
    margin-left: 40px;
}

.inr2-3,
.inr2-4 {
    border-radius: 20px;
    box-shadow: 0 10px 60px rgba(234, 234, 234, 0), 0 0 20px rgba(234, 233, 233, 0.04), inset 0 0 20px rgba(255, 255, 255, 0.5);
    align-items: center;
    width: 490px;
    border-bottom: 10px solid rgb(84, 131, 179);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-width: 8px;
    margin: 40px 0px 0px 14px;
    background-color: rgb(255, 255, 255);
}

.inr2-3:hover,
.inr2-4:hover {
    transform: scale(1.04);
    background-color: rgb(255, 255, 255);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
}

.inr2-3::before,
.inr2-4::before {
    transition: transform 0.3s ease-in-out;
}

.inr2-4 {
    animation: borderAnimations 20s infinite;
    border-left-width: 4px;
    border-right-width: 4px;
    border-top-width: 4px;
    border-style: solid;
}

@keyframes borderAnimations {
    20% {
        border-left-color: rgb(84, 131, 179);
        border-right-color: #fcf9fb;
        border-top-color: #fefcfd;
    }
    40% {
        border-top-color: rgb(84, 131, 179);
        border-left-color: #f8f8f8;
        border-right-color: #fffdfe;
    }
    60% {
        border-right-color: rgb(84, 131, 179);
        border-left-color: #ffffff;
        border-top-color: #ffffff;
    }
    100% {
        border-bottom-color: rgb(84, 131, 179);
        border-left-color: rgb(84, 131, 179);
        border-right-color: rgb(84, 131, 179);
        border-top-color: rgb(84, 131, 179);
    }
}

.inr2-3 p,
.inr2-4 p {
    font-weight: 400;
    font-size: 1rem;
    text-align: start;
}

.singlemembership-monthly-plan {
    background-color: rgb(5, 38, 89);
    font-size: 26px;
    font-weight: 700;
    text-align: center;
    color: white;
    padding: 10px 0px 10px 0px;
    border-radius: 10px;
}

.singlemembership-inr,
.singlemembership-inr-inr1 {
    font-size: 26px;
    font-weight: 700;
    color: rgb(84, 131, 179);
    text-align: center;
}

.singlemembership-inr,
.singlemembership-inr-inr1 {
    margin-left: 114px;
}

.Internet-of-Things-1-learning-paths,
.choosespan-students,
.singlemembership-month {
    font-weight: 400;
    font-size: 18px;
}

.singlemembership-mo {
    font-weight: 900;
    font-size: 36px;
    text-align: center;
}

.per-month {
    font-weight: 400;
    font-size: 1.6rem;
    color: rgb(87, 85, 85);
}

.Internet-of-Things-1-learning-pathses {
    text-decoration: line-through;
}

.btn-iotrevolution-students,
.btn-iotrevolution-students-annual {
    background: linear-gradient(135deg, rgb(84, 131, 179), rgb(5, 38, 89));
    color: rgb(255, 255, 255);
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-left: 100px;
    width: 250px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
}

.btn-iotrevolution-students-annual {
    margin-top: 20px;
}

.fontawsomeicon-companies {
    background-color: rgb(5, 38, 89);
    width: 20px;
    height: 20px;
    color: rgb(255, 255, 255);
}

.btn-iotrevolution-students:hover,
.btn-iotrevolution-students-annual:hover {
    background: linear-gradient(135deg, rgb(5, 38, 89), rgb(84, 131, 179));
    transform: scale(1.1) translateY(-1px);
}

.students-inr2-3-background-hr hr {
    border: none;
    border-top: 10px dotted rgb(84, 131, 179);
    margin: 20px 0;
}

@media (max-width: 480px) {
    .students-inr2-3-lakh-flex {
        flex-direction: column;
    }
    .students-inr2-3-background,
    .students-inr2-4-background {
        width: 320px;
        height: auto;
        border-radius: 0px;
    }
    .students-inr2-4-background {
        margin-left: 0px;
    }
    .bestplanchoose-students {
        font-size: 24px;
    }
    .inr2-3,
    .inr2-4 {
        margin-left: 10px;
        margin-right: 10px;
        width: auto;
        height: auto;
        border-radius: 0px;
    }
    .inr2-3 p,
    .inr2-4 p {
        font-size: 1rem;
    }
    .singlemembership-monthly-plan {
        border-radius: 0px;
    }
    .singlemembership-inr,
    .singlemembership-inr-inr1,
    .singlemembership-monthly-plan {
        font-size: 20px;
    }
    .singlemembership-inr {
        margin-left: 50px;
    }
    .Internet-of-Things-1-learning-paths,
    .choosespan-students,
    .singlemembership-month,
    .per-month {
        font-size: 1.2rem;
    }
    .singlemembership-mo {
        font-size: 24px;
    }
    .for-single-month {
        font-size: 20px;
        color: #0e0e0e;
    }
    .iotrevolution {
        width: 250px;
    }
    .iotrevolution-month {
        margin: 10px 0px 0px 10px;
    }
    .btn-iotrevolution-students,
    .btn-iotrevolution-students-annual {
        width: 200px;
        margin-left: 35px;
        border-radius: 0px;
        font-size: 20px;
    }
    .btn-iotrevolution-students-annual {
        margin-top: 40px;
    }
}


/* Tablet styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .students-inr2-3-background,
    .students-inr2-4-background {
        width: 480px;
        margin: 40px 0px 40px 0px;
        margin-left: auto;
        margin-right: auto;
    }
    .inr2-3,
    .inr2-4 {
        width: 460px;
        margin: 0px 10px 0px 10px;
    }
}