.internet-courses-ourney1,
.internet-courses-ourney2,
.internet-courses-ourney3 {
    display: flex;
    gap: 44px;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: start;
    margin-top: 20px;
}

.internet-courses-ourney2,
.internet-courses-ourney3 {
    margin-top: 60px;
}

.IoTUniverse-images {
    width: 50px;
    height: 50px;
    display: block;
    margin: 40px;
}

.iot-development-icon__microchip {
    color: white;
    width: 50px;
    height: 50px;
    margin: 0px 10px 0px 10px;
}

.internet-courses-ourney-navbar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.internet-courses-ourney-nav-item {
    position: relative;
    display: block;
    cursor: pointer;
    color: white;
    width: 380px;
    text-align: center;
    background-color: #052659;
    border-radius: 4px;
    padding: 14px;
    margin: 20px;
    font-size: 1rem;
    font-weight: 700;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    border-left: 4px solid white;
    border-right: 4px solid white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.1), inset 0 0 4px rgba(255, 255, 255, 0.5);
    animation: boxShadowAnimation 2s infinite;
}

.internet-courses-ourney-nav-item:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(0, 0, 0, 0.1), inset 0 0 0px rgba(255, 255, 255, 0.5);
    animation: boxShadowAnimation 0s infinite;
    color: white;
    display: block;
    list-style: none;
}

.internet-courses-ourney-nav-item:hover .internet-courses-ourney-dropdown-menu {
    display: block;
    list-style: none;
}

.internet-courses-ourney-dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
    padding: 10px;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;
    text-align: left;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 1rem;
    font-weight: 400;
}

.internet-courses-ourney-dropdown-menu li {
    padding: 10px;
    color: #555;
}

.internet-courses-ourney-dropdown-menuli:hover {
    background-color: #f5f5f5;
}

@media (max-width: 480px) {
    .internet-courses-ourney1,
    .internet-courses-ourney2,
    .internet-courses-ourney3 {
        flex-wrap: wrap;
        gap: 0px;
        margin-left: 0px;
        margin-right: 40px;
    }
    .internet-courses-ourney2,
    .internet-courses-ourney3 {
        margin-top: 0px;
    }
    .iot-development-icon__microchip {
        width: 30px;
        height: 30px;
        margin-right: 5px;
    }
    .internet-courses-ourney-nav-item {
        width: 100%;
        padding: 10px;
        font-size: 0.9rem;
        margin-bottom: 40px;
    }
    .internet-courses-ourney-dropdown-menu {
        font-size: 1rem;
        padding: 5px;
    }
    .internet-courses-ourney-dropdown-menu li {
        padding: 5px;
    }
}

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .internet-courses-ourney1,
    .internet-courses-ourney2,
    .internet-courses-ourney3 {
        margin-left: 10px;
        margin-right: 40px;
    }
    .internet-courses-ourney2,
    .internet-courses-ourney3 {
        margin-top: 40px;
    }
    .iot-development-icon__microchip {
        width: 35px;
        height: 35px;
        margin-right: 7px;
    }
    .internet-courses-ourney-nav-item {
        width: 100%;
        padding: 12px;
        font-size: 1rem;
    }
    .internet-courses-ourney-dropdown-menu {
        font-size: 1.1rem;
        padding: 8px;
    }
    .internet-courses-ourney-dropdown-menu li {
        padding: 8px;
    }
}