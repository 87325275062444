.signup-pages-flex {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 91vh;
}

.studentssign-cssimagesflex-color {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
    padding: 20px;
}

.studentssignup-cssimagesflex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

#studentssign-cssimagesflex-color {
    margin: 20px;
}

.students-signupimage {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 520px;
    margin: 20px 0px;
}

.students-loginimage {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 540px;
}

.toggle-password-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.students-signup-background-color {
    border-radius: 8px;
    padding: 10px;
    width: 100%;
}

.center-signup {
    font-size: 1.8rem;
    font-weight: 700;
    font-weight: bold;
    color: #043885;
}

.recaptcha-container{
    margin: 20px 0px;
}

.students-signup-btn {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    padding: 12px;
    font-weight: bold;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.users-login-btn {
    margin-top: 14px;
}

.students-frogot-password {
    margin-top: 20px;
    color: #043885;
    font-weight: 500;
    text-decoration: none;
}

.students-frogot-password:hover {
    text-decoration: underline;
}

.companies-already-account {
    margin-top: 20px;
}

.students-signup-btn:hover {
    background: linear-gradient(135deg, #052659, #5483B3);
}

.loginhr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    text-align: center;
}

.loginhrline,
.loginhrline1 {
    width: 28%;
    border: 2px solid #5b5959;
}

.login-hr-or {
    margin: 0 10px;
    color: #555;
}

.linkedin-google {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.signingoogle-google-login {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.signingoogle-google-login:hover {
    transform: scale(1.4);
}

.googleicon {
    width: 40px;
    height: auto;
}

.students-signup-accounts{
    margin: 20px 0px 0px 0px;
}
.signup-already-account {
    font-size: 0.875rem;
    color: #555;
}

.signuphere {
    font-size: 0.875rem;
    color: #043885;
    text-decoration: none;
    font-weight: bold;
}

.signuphere:hover {
    text-decoration: underline;
}

.toastcontainer{
    margin-top: 20px;
}

/* Responsive Styles */

@media (max-width: 768px) {
    .studentssignup-cssimagesflex {
        flex-direction: column;
        align-items: center;
        margin-right: 10px;
    }
    #studentssign-cssimagesflex-color {
        margin-right: 20px;
    }
    .students-signupimage,
    .students-loginimage {
        height: 500px;
    }
    .students-signup-background-color {
        padding: 20px;
        width: 90%;
    }
}

@media (max-width: 480px) {
    .studentssign-cssimagesflex-color {
        padding: 20px;
    }
    .studentssignup-cssimagesflex {
        padding-top: 10px;
    }
    #studentssign-cssimagesflex-color {
        margin: 0px;
    }
    .center-signup {
        font-size: 1.5rem;
    }
    #signup-user {
        margin-top: -60px;
    }
    .students-signupimage,
    .students-loginimage {
        width: 0%;
        height: 0px;
        border-radius: 0px;
        box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
    }
    .students-signup-btn {
        padding: 10px;
        font-size: 0.875rem;
    }
}