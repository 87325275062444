.admin-dashboard-users-data {
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
    width: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
    height: 100%;
    font-family: inter, Arial, sans-serif;
    line-height: initial;
}

.admin-dashboard-users-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.admin-dashboard-logout-button {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    font-size: 20px;
    font-weight: 400;
    padding: 6px;
    margin-left: auto;
    border-radius: 20px;
}

.admin-dashboard-users-data-h1 {
    text-align: center;
    font-size: 46px;
    font-weight: 700;
    color: rgb(5, 38, 89);
}

.admin-dashboard-users-datas {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;
}

.admindashboard-link {
    background: linear-gradient(135deg, #5483B3, #052659);
    padding: 10px 20px 0px 20px;
    border-radius: 20px;
}

.admin-dashboard-users-datas a {
    text-decoration: none;
    color: #ffff;
    font-size: 24px;
    font-weight: 700;
}


/* signup */

.admin-signup-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 80px;
}

.admin-signup-background-color {
    width: 500px;
}


/* students */

.admin-students-dashboard {
    height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.admin-students-dashboard-p {
    font-size: 24px;
    font-weight: bold;
    color: #052659;
    margin-bottom: 20px;
    text-align: center;
}

.index-number {
    font-weight: bold;
    text-align: center;
    color: #333;
}

.admin-students-dashboard-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.admin-students-dashboard-table th,
.admin-students-dashboard-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.admin-students-dashboard-table th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
}

.admin-students-dashboard-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.admin-students-dashboard-table tr:hover {
    background-color: #f1f1f1;
}

.admin-students-dashboard-table th,
.admin-students-dashboard-table td {
    border-bottom: 1px solid #ddd;
}

.admin-students-dashboard-table th {
    border-top: 2px solid #052659;
    border-bottom: 2px solid #052659;
}

.admin-students-dashboard-table ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.admin-students-dashboard-table ul li {
    padding: 2px 0;
}


/* Companies */

.admin-dashboard-project-type {
    margin-top: 20px;
}

.admin-dashboard-projects-span,
.admin-dashboard-projects-download-button {
    font-size: 1.2rem;
    font-weight: 700;
}

.admin-dashboard-projects-download-button {
    background-color: #052659;
    color: white;
}