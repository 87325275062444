.students-flex {
    width: 100%;
}

.studentsjoin-span {
    margin-left: 80px;
}

.homepagecolores {
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
}

.students-getstarted-flex-1 {
    background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #052659 100%);
}

.studentsjoin,
.spansexports1,
.learningpath-ourney-1h1,
.learningpath-ourney-learning-paths,
.learn-transformation-experience,
.how-it-works,
.step-title,
.step-text,
.Unemployability-1,
.Unemployability-p,
.achievements-h1,
.experience-learners-h1,
.experience-learners-p,
.learn-transformation-experience-paths-h1,
.learningpath-ourney-1p,
.learningpath-ourney-1p1,
.learningpath-ourney-2h1 {
    color: rgb(5, 38, 89);
}

.studentsjoin {
    font-weight: 700;
    font-size: 40px;
    background-image: linear-gradient( -225deg, rgb(5, 38, 89) 0%, rgb(5, 38, 89) 29%, #033a8e 67%, #033a8e 100%);
    background-size: 200%;
    color: rgb(255, 255, 255);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 4s linear infinite;
}

@keyframes textclip {
    0% {
        background-position: 200% center;
    }
    100% {
        background-position: 0% center;
    }
}

.spansexports1 {
    font-size: 1.2rem;
    line-height: 2rem;
    font-weight: 400;
    color: #000000;
    margin: 0px 40px 0px 4px;
}

.buttonget-link {
    padding-top: 14px;
    margin-left: 80px;
}

.buttonget-1 {
    background: linear-gradient(135deg, #5483B3, #052659);
    color: white;
    border: none;
    cursor: pointer;
    transition: transform 0.3s ease;
    margin-left: 10px;
    width: 200px;
    height: 40px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.25;
    margin-right: 20px;
}

.buttonget-1:hover {
    background: linear-gradient(135deg, #052659, #5483B3);
}

.students-Thirdman {
    width: 580px;
    height: 560px;
    margin: 20px 80px 0px 0px;
}

.learningpath-ourney {
    padding-bottom: 100px;
}

.learningpath-ourney-1h1 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    padding: 40px 0px 0px 0px;
}

.learningpath-ourney-1p,
.learningpath-ourney-1p1 {
    text-align: center;
    margin: 0;
    font-weight: 400;
}

.learningpath-ourney-1p {
    font-size: 1.2rem;
    line-height: 2rem;
    color: #000000;
}

.learning-paths-icon-flex {
    display: flex;
    padding: 10px 0px 0px 0px;
    margin: 40px 0px 10px 0px;
}

.learn-transformation-experience-paths-h1 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    margin-left: auto;
    margin-top: 28px;
}

.learning-paths-icon__icon {
    width: 60px;
    height: 60px;
    margin-right: auto;
    animation-name: igniteAnimation;
    animation-duration: 10s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}

.learn-transformation-experience {
    font-weight: 400;
    font-size: 1.2rem;
    line-height: 2rem;
    text-align: center;
    color: #000000;
}

.background-colors-works {
    margin: 20px 0px 0px 0px;
    padding: 20px 0px 40px 0px;
}

.how-it-works {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.colorwhitecard {
    background-color: white;
    border-radius: 20px;
    margin: 0px 140px 40px 150px;
}

.colorwhitecards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.cardflexs-1 {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    margin-top: 20px;
}

.Shirt {
    width: 70px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.steps1,
.steps2,
.steps3,
.steps4,
.steps5,
.steps6 {
    flex: 1 1 30%;
    max-width: 35%;
    text-align: center;
    padding-top: 10px;
    margin: 0px;
}

.step-title {
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: 600;
    font-size: 1.4rem;
    text-align: center;
}

.step-text {
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
}

.steps1,
.steps2,
.steps3 {
    border-bottom: 2px solid #ddd;
    animation: borderColorAnimation 4s infinite;
}

.steps1,
.steps4,
.steps5,
.steps2 {
    border-right: 2px solid #ddd;
    animation: borderColorAnimation 4s infinite;
}

@keyframes borderColorAnimation {
    0% {
        border-color: #ddd;
    }
    50% {
        border-color: #043b8d;
    }
    100% {
        border-color: #ddd;
    }
}

.brz-icon-system {
    width: 80px;
    height: 80px;
    font-weight: 600;
    margin-top: 20px;
}

.inrperflex {
    margin-top: 100px;
}

.Unemployability {
    background-color: white;
    padding: 40px 0px 40px 0px;
}

.Unemployability-1 {
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    padding: 0px 20px 0px 20px;
}

.Unemployability-p {
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
    line-height: 1rem;
    margin: 20px auto;
    color: #000000;
}

.achievements-h1 {
    font-weight: 700;
    font-size: 30px;
    text-align: center;
    padding-top: 20px;
    margin-bottom: 40px;
}

.carouselflex-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.carousels-1 {
    max-width: 100%;
    height: 400px;
    border-radius: 10px;
    margin: auto;
    padding-top: 10px;
}

.experience-learners {
    background-color: #ffff;
    padding: 10px 0px 60px 0px;
}

.experience-learners-h1 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 30px;
    padding-top: 40px;
    text-align: center;
}

.experience-learners-p {
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
    padding-bottom: 40px;
    color: #000000;
}

.students-Reviews1 {
    max-width: 1400px;
    margin: 20px;
}

.Review1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Review1 {
    width: 440px;
    height: 360px;
    border-radius: 20px;
    padding: 10px;
}

.backgroundimages-1 {
    background: linear-gradient(to bottom, rgb(244, 247, 252), rgb(102, 101, 101));
}

.backgroundcolorin-images {
    background: linear-gradient(130deg, hwb(210 33% 30%) 4%, hsl(215, 90%, 18%) 50%);
    width: 100%;
    height: auto;
    padding: 60px 20px 60px 20px;
}

.backgroundcolorin-images-ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: start;
}

.backgroundcolorin-images-ul ul {
    list-style: none;
    padding-top: 70px;
}

.backgroundcolorin-experience-learn-h1 {
    font-size: 40px;
    font-weight: 600;
    line-height: 1;
    color: white;
}

.backgroundcolorin-experience-p {
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.2rem;
    color: white;
}

.buttonget-join-now-btn {
    color: #0d0d0d;
    border: 0px solid;
    cursor: pointer;
    transition: transform 0.3s ease;
    width: 200px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 20px;
    font-weight: 700;
    font-size: 20px;
    background-color: #fcf9f9;
}

.buttonget-join-now-btn:hover {
    background-color: #fcf9f9;
    transform: scale(1.1) translateY(-5px);
}

.buttonget-join-now-btn-link {
    padding-top: 20px;
    margin-left: 10px;
}

.buttonget-join-now-btn-hello-huebits {
    color: #ffffff;
    font-size: 1.4rem;
    font-weight: 400;
}

.headway-images,
.headway-imageses {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    margin-left: 20px;
}

.headway-image {
    width: 350px;
    height: 350px;
    margin-left: 20px;
    border-radius: 20px;
}

@media (max-width: 480px) {
    .students-getstarted-flex-1 {
        background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
    }
    .studentsjoin-span {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .studentsjoin {
        font-size: 30px;
        margin: 40px 0px 0px 0px;
    }
    .studentsjoinbuild {
        font-size: 30px;
        padding-right: 40px;
    }
    .spansexports1 {
        font-size: 1.2rem;
    }
    .buttonget-link {
        display: flex;
        margin: 0px 20px 0px 4px;
    }
    .buttonget-1 {
        width: 130px;
        font-size: 16px;
        height: 35px;
        padding-bottom: 4px;
        border-radius: 0px;
        margin-left: 0px;
    }
    .students-Thirdman {
        width: 300px;
        height: auto;
        padding-top: 40px;
        margin: auto;
    }
    .spanes-1 {
        font-size: 1.2rem;
        color: #0e0e0e;
        text-align: start;
    }
    .learningpath-ourney {
        padding-bottom: 10px;
    }
    .learningpath-ourney-1h1 {
        font-size: 24px;
        text-align: center;
        padding: 20px 20px 0px 20px;
    }
    .learningpath-ourney-1p {
        margin: 20px 20px 0px 20px;
    }
    .learningpath-ourney-1p1 {
        line-height: 1;
        font-size: 1.2rem;
        margin: 10px 20px 0px 20px;
    }
    .learningpath-ourney1 {
        margin-right: 20px;
        padding: 40px 0px 20px 10px;
    }
    .background-colors-works {
        padding: 20px 0px 60px 0px;
    }
    .how-it-works {
        font-size: 24px;
        text-align: center;
        margin: 20px;
    }
    .colorwhitecard {
        margin: 0px 20px 20px 20px;
    }
    .colorwhitecards {
        justify-content: column;
        align-items: center;
    }
    .cardflexs-1 {
        margin-top: 10px;
    }
    .steps1,
    .steps2,
    .steps3,
    .steps4,
    .steps5,
    .steps6 {
        flex: 1 100%;
        max-width: 100%;
        text-align: center;
        border: 0px solid #ddd;
        padding: 10px;
        margin: 0px;
    }
    .carouselflex-1 {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .carousels-1 {
        max-width: 100%;
        height: 200px;
        margin: auto;
        padding: 10px;
        border-radius: 10%;
    }
    .backgroundimage-1 {
        padding: 1.5%;
    }
    .Unemployability {
        padding: 40px 0px 60px 0px;
    }
    .Unemployability-1 {
        margin-right: 20px;
        font-size: 24px;
        text-align: center;
        padding-left: 20px;
    }
    .Unemployability-p {
        font-size: 1.2rem;
        line-height: 2rem;
        margin: 0px 20px 0px 20px;
    }
    .experience-learners-h1 {
        font-size: 24px;
    }
    .experience-learners {
        padding: 10px;
    }
    .experience-learners-p {
        font-size: 1.2rem;
        padding-bottom: 20px;
        margin: 0px 20px 0px 20px;
    }
    .achievements-h1 {
        font-size: 24px;
        padding-top: 10px;
    }
    .Review1 {
        width: 300px;
        height: 300px;
        border-radius: 0px;
    }
    .backgroundimages-1 {
        height: auto;
        width: auto;
    }
    .backgroundcolorin-images {
        width: auto;
        height: auto;
        border-radius: 0px;
        padding: 0px 0px 20px 0px;
        margin: 0px -10px 0px -10px;
    }
    .backgroundcolorin-images-ul {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .backgroundcolorin-images-ul ul {
        text-align: center;
        padding: 20px 10px 10px 10px;
    }
    .backgroundcolorin-experience-learn-h1,
    .backgroundcolorin-experience-learns-h1 {
        font-size: 28px;
        line-height: 1.5rem;
        text-align: center;
        padding: 0px;
    }
    .backgroundcolorin-experience-p {
        font-size: 1.2rem;
        line-height: 1.5rem;
        text-align: center;
        padding: 0px 20px 0px 20px;
    }
    .buttonget-join-now-btn {
        width: 160px;
        height: 36px;
        font-size: 16px;
        border-radius: 0px;
        margin-right: 35px;
    }
    .buttonget-join-now-btn-link {
        padding-top: 20px;
    }
    .headway-images {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
    .headway-imageses {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
    .headway-image {
        width: 320px;
        height: 320px;
        border-radius: 0px;
        margin: 0px 0px 20px 0px;
    }
    .footer-content-background-color {
        padding: 20px 0px 20px 0px;
    }
    .students-footer {
        flex-direction: column;
    }
    .footer-content-background-color-huebits {
        font-size: 1.6rem;
        margin: 0px 10px 0px 20px;
    }
    .footer-content-background-color-students {
        margin-left: 20px;
    }
    .footer-content-background-color-companies {
        margin-left: 20px;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .students-getstarted-flex-1 {
        background: linear-gradient(to right, #eff2f7 0%, rgba(215, 238, 252, 0.6) 25%, #d0e4f9 50%, rgba(197, 231, 252, 0.6) 75%, #ffffff 100%);
    }
    .studentsjoin-span {
        margin-left: 0px;
    }
    .studentsjoin {
        padding: 40px 10px 0px 0px;
    }
    .spansexports1 {
        padding: 0px 10px 0px 0px;
    }
    .buttonget-link {
        padding-top: 10px;
        margin-left: 0px;
    }
    .buttonget-1 {
        border-radius: 20px;
    }
    .students-Thirdman {
        width: auto;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }
    .learningpath-ourney{
        padding: 0px 20px;
    }
    .learningpath-ourney-1h1 {
        padding: 20px 20px 0px 20px;
        line-height: 1.5;
    }
    .learningpath-ourney1 {
        height: auto;
        width: auto;
        margin-bottom: 0px;
        padding: 40px 0px 0px 20px;
    }
    .learning-paths-icon-flex {
        margin-top: 0px;
    }
    .learn-transformation-experience-paths-h1 {
        font-weight: 700;
        text-align: center;
    }
    .learn-transformation-experience {
        margin: 20px 40px 0px 40px;
    }
    .colorwhitecard {
        margin: 0px 20px 0px 20px;
    }
    .achievements-h1 {
        padding-top: 10px;
    }
    .Review1 {
        width: 550px;
        height: auto;
        border-radius: 20px;
        margin: 0;
        padding: 0px;
    }
    .backgroundcolorin-images {
        height: auto;
        padding: 40px 14px 40px 0px;
    }
    .backgroundcolorin-images-ul {
        justify-content: center;
        text-align: center;
        margin-left: 0px;
    }
    .backgroundcolorin-images-ul ul {
        padding-top: 20px;
    }
    .headway-images,
    .headway-imageses {
        justify-content: start;
        align-items: start;
        margin-left: auto;
        margin-right: auto;
    }
    .headway-image {
        width: 350px;
        height: 350px;
    }
}