.students-Mentorship-slider-container {
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
}

.students-Mentorship-slide-item {
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.studentsMentorship-image {
    width: 400px;
    height: auto;
    border-radius: 20px;
    margin: 0px 20px 0px 40px;
}

.students-Mentorship-slide-item ul {
    list-style: none;
    flex-grow: 1;
    margin: 0px 40px 0px 10px;
    background-color: white;
    padding: 60px 10px 60px 40px;
    border-top-left-radius: 100px;
    border-bottom-right-radius: 100px;
    border-left: 4px solid #052659;
    border-right: 4px solid #052659;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
}

.students-Mentorship-slide-item-h1 {
    color: #052659;
    font-weight: 700;
    font-size: 1.4rem;
    text-align: start;
    line-height: 2rem;
}

.students-Mentorship-slide-item-p {
    color: black;
    font-weight: 400;
    line-height: initial;
    font-size: 1.2rem;
    text-align: start;
}

@media (max-width: 480px) {
    .students-Mentorship-slider-container {
        margin-left: auto;
        margin-right: auto;
        padding: 10px;
    }
    .studentsMentorship-image {
        width: 300px;
        border-radius: 0px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 20px;
    }
    .students-Mentorship-slide-item ul {
        margin-left: auto;
        margin-right: auto;
        width: 360px;
    }
    .students-Mentorship-slide-item-h1 {
        margin-top: 10px;
    }
}

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .students-Mentorship-slider-container {
        margin-left: 20px;
        margin-right: auto;
        padding: 20px 0px 80px 0px;
    }
    .students-Mentorship-slide-item ul {
        margin-top: 20px;
        box-shadow: 0 0px 10px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.5);
    }
    .studentsMentorship-image {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .students-Mentorship-slide-item-h1,
    .students-Mentorship-slide-item-p {
        padding: 20px 20px 0px 20px;
        text-align: start;
    }
}