.learningpath-ourney1,
.learningpath-ourneys,
.learningpath-ourneyes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
}

.learningpath-ourney1 {
    margin: 20px auto 0;
    padding: 0 0 20px;
}

.learningpath-ourneys {
    margin: 50px auto 0;
}

.learningpath-ourneyes {
    margin: 40px auto 0;
    padding-bottom: 40px;
}

.learningpath-ourney-navbar-nav {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.learningpath-ourney-nav-item {
    position: relative;
    display: block;
    padding: 20px;
    cursor: pointer;
    color: black;
    width: 410px;
    text-align: center;
    background-color: #fff;
    border-radius: 4px;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    font-size: 1.1rem;
    font-weight: 700;
    border-left: 4px solid #052659;
    border-right: 4px solid #052659;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2), 0 0 4px rgba(0, 0, 0, 0.1), inset 0 0 4px rgba(255, 255, 255, 0.5);
    animation: boxShadowAnimation 2s infinite;
}

.learningpath-ourney-nav-item:hover {
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2), 0 0 0px rgba(0, 0, 0, 0.1), inset 0 0 0px rgba(255, 255, 255, 0.5);
    animation: boxShadowAnimation 0s infinite;
    color: #052659;
}

.learningpath-ourney-nav-item:hover .learningpath-ourney-dropdown-menu {
    display: block;
    list-style: none;
}

.learningpath-ourney-dropdown-menu {
    display: none;
    position: absolute;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(255, 255, 255, 0.5);
    z-index: 1;
    padding: 10px;
    width: auto;
    border-top-left-radius: 60px;
    border-bottom-right-radius: 60px;
    border-left: 4px solid #052659;
    border-right: 4px solid #052659;
    text-align: left;
    top: 100%;
    left: 0;
    right: 0;
    font-size: 1rem;
    font-weight: 400;
}

.learningpath-ourney-dropdown-menu li {
    padding: 10px;
    color: #000000;
}
/* 
.learningpath-ourney-dropdown-menu li:hover {
    background-color: #f5f5f5;
} */

.ShirtBusinessIcon {
    width: 80px;
    height: 60px;
}

.spansp-get {
    margin-bottom: 20px;
    font-weight: 400;
    line-height: initial;
    font-size: 1.2rem;
    color: #0e0e0e;
    text-align: start;
    text-align: center;
}

.students-learning-patha-cardflexs {
    display: flex;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 80px;
}

.students-learning-patha-card,
.companies-learning-patha-card {
    width: 350px;
    height: 400px;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    border: 1px solid rgb(161, 161, 161);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease; 
    /* border-bottom: 10px solid #052659; */
    padding-bottom: 10px;
}

.students-learning-patha-card:hover {
    background-color: #f8f8fa;
    transform: translateY(-8px); 
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);}

/* .companies-learning-patha-card {
    width: 300px;
    height: 420px;
    background-color: #f2f2f3;
    border-bottom: 10px solid #052659;
    transform: scale(1);
} */

.companies-learning-patha-card:hover {
    background-color: #ffffff;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2), 0 0 20px rgba(0, 0, 0, 0.1), inset 0 0 20px rgba(255, 255, 255, 0.5);
}

.students-learning-patha-card-image {
    width: 349px;
    height: 160px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.students-learning-patha-card-title,
.students-learning-patha-card-text {
    color: #052659;
}

.students-learning-patha-card-title {
    font-size: 1rem;
    font-weight: 700;
    margin-top: 10px;
    padding: 0px 0px 0px 10px;
}

.students-learning-patha-card-text {
    font-size: 1rem;
    font-weight: 400;
    padding: 0px 10px 0px 10px;
}

.students-button-Learn-More {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.students-button-Learn-More .learn-more {
    background: linear-gradient(135deg, #5483B3, #052659);
    margin-top: 24px;
}

.students-button-Learn-More .learn-more:hover{
    background: linear-gradient(135deg, #052659, #5483B3);
    transform: scale(1.1);
}

/* .companies-button-Learn-More2 {
    margin-top: 60px;
} */

.students-Learn-More-button {
    color: white;
    border: none;
    cursor: pointer;
    width: 180px;
    height: 36px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 20px;
    background: linear-gradient(135deg, #5483B3, #052659);
}
.students-Learn-More-button:hover{
    background: linear-gradient(135deg, #052659, #5483B3);
    transform: scale(1.1);
}
.leaarners-coming-soon,
.leaarners-coming-soon1{
    border: none;
    cursor: pointer;
    width: 180px;
    height: 36px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 20px;
    color: #636363;
}
.leaarners-coming-soon1{
    margin-top: 24px;
}

.companies-button-Learn-More{
    margin-top: 60px;

}
.companies-button-Learn-More2 {
    margin-top: 40px;
}

.coming-soon1{
    border: none;
    cursor: pointer;
    width: 180px;
    height: 36px;
    border-radius: 4px;
    font-weight: 700;
    font-size: 20px;
    color: #636363;
    margin-top: 40px;
}


/* Phone Styling */

@media (max-width: 480px) {
    .learningpath-ourney1,
    .learningpath-ourneys,
    .learningpath-ourneyes {
        flex-direction: column;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 0px;
    }
    .learningpath-ourneyes {
        margin-left: 20px;
        margin-right: 20px;
    }
    .learningpath-ourneys {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 0px;
    }
    .learningpath-ourney-nav-item {
        width: 100%;
        padding: 14px;
        font-size: 0.9rem;
        margin-bottom: 40px;
    }
    .learningpath-ourney-dropdown-menu {
        font-size: 1rem;
    }
    .students-learning-patha-cardflexs {
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
    }
    .students-learning-patha-card {
        width: 300px;
        height: auto;
    }
    .companies-learning-patha-card {
        width: 300px;
        height: auto;
    }
    .students-learning-patha-card-image {
        width: 298px;
        height: auto;
    }
    .students-learning-patha-card-title,
    .students-learning-patha-card-text {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}


/* Tablet Styling */

@media (min-width: 481px) and (max-width: 1023px),
(min-width: 768px) and (max-width: 1023px),
(min-width: 1024px) and (max-width: 1279px) {
    .learningpath-ourney1,
    .learningpath-ourneys,
    .learningpath-ourneyes {
        gap: 15px;
        padding: 10px;
    }
    .learningpath-ourney1 {
        margin: 0px 20px 0px 0px;
    }
    .learningpath-ourneys {
        margin: 20px auto 0;
    }
    .learningpath-ourneyes {
        margin: 20px auto 0;
    }
    .learningpath-ourney-nav-item {
        width: 100%;
        padding: 15px;
        font-size: 1rem;
        margin-bottom: 40px;
    }
    .learningpath-ourney-dropdown-menu {
        font-size: 1.1rem;
    }
    .students-learning-patha-card {
        width: 300px;
        height: 380px;
    }
    .students-learning-patha-card-image {
        width: 300px;
        height: 110px;
    }
    .companies-learning-patha-card {
        width: 300px;
        height: auto;
    }
    .leaarners-coming-soon{
        margin-top: 24px;
    }
}